const Ziggy = {
    url: "https://flexable.nexuslink.in",
    port: 8000,
    defaults: {},
    routes: {
        "debugbar.openhandler": {
            uri: "_debugbar/open",
            methods: ["GET", "HEAD"],
        },
        "debugbar.clockwork": {
            uri: "_debugbar/clockwork/{id}",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "debugbar.telescope": {
            uri: "_debugbar/telescope/{id}",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "debugbar.assets.css": {
            uri: "_debugbar/assets/stylesheets",
            methods: ["GET", "HEAD"],
        },
        "debugbar.assets.js": {
            uri: "_debugbar/assets/javascript",
            methods: ["GET", "HEAD"],
        },
        "debugbar.cache.delete": {
            uri: "_debugbar/cache/{key}/{tags?}",
            methods: ["DELETE"],
            parameters: ["key", "tags"],
        },
        "l5-swagger.default.api": {
            uri: "api/documentation",
            methods: ["GET", "HEAD"],
        },
        "l5-swagger.default.docs": {
            uri: "api/default/docs/{jsonFile?}",
            methods: ["GET", "HEAD"],
            parameters: ["jsonFile"],
        },
        "l5-swagger.default.asset": {
            uri: "api/default/docs/asset/{asset}",
            methods: ["GET", "HEAD"],
            parameters: ["asset"],
        },
        "l5-swagger.default.oauth2_callback": {
            uri: "api/default/callback",
            methods: ["GET", "HEAD"],
        },
        "l5-swagger.v1.api": {
            uri: "api/v1/documentation",
            methods: ["GET", "HEAD"],
        },
        "l5-swagger.v1.docs": {
            uri: "docs/{jsonFile?}",
            methods: ["GET", "HEAD"],
            parameters: ["jsonFile"],
        },
        "l5-swagger.v1.asset": {
            uri: "docs/asset/{asset}",
            methods: ["GET", "HEAD"],
            parameters: ["asset"],
        },
        "l5-swagger.v1.oauth2_callback": {
            uri: "api/oauth2-callback",
            methods: ["GET", "HEAD"],
        },
        "filament.asset": {
            uri: "filament/assets/{file}",
            methods: ["GET", "HEAD"],
            wheres: { file: ".*" },
            parameters: ["file"],
        },
        "filament.auth.logout": { uri: "filament/logout", methods: ["POST"] },
        "filament.auth.login": { uri: "admin/login", methods: ["GET", "HEAD"] },
        "filament.pages.dashboard": { uri: "admin", methods: ["GET", "HEAD"] },
        "filament.resources.affiliated-organizations.index": {
            uri: "admin/affiliated-organizations",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.affiliated-organizations.create": {
            uri: "admin/affiliated-organizations/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.affiliated-organizations.edit": {
            uri: "admin/affiliated-organizations/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.blogs.index": {
            uri: "admin/blogs",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.blogs.create": {
            uri: "admin/blogs/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.blogs.edit": {
            uri: "admin/blogs/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.job-offer-company-plan-extras.index": {
            uri: "admin/job-offer-company-plan-extras",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offer-company-plan-extras.create": {
            uri: "admin/job-offer-company-plan-extras/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offer-company-plan-extras.edit": {
            uri: "admin/job-offer-company-plan-extras/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.job-offer-company-plans.index": {
            uri: "admin/job-offer-company-plans",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offer-company-plans.create": {
            uri: "admin/job-offer-company-plans/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offer-company-plans.edit": {
            uri: "admin/job-offer-company-plans/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.job-offer-companies.index": {
            uri: "admin/job-offer-companies",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offer-companies.create": {
            uri: "admin/job-offer-companies/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offer-companies.edit": {
            uri: "admin/job-offer-companies/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.job-offers.index": {
            uri: "admin/job-offers",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offers.create": {
            uri: "admin/job-offers/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.job-offers.edit": {
            uri: "admin/job-offers/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.cms/menus.index": {
            uri: "admin/cms/menus",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.cms/menus.create": {
            uri: "admin/cms/menus/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.cms/menus.edit": {
            uri: "admin/cms/menus/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.cms/pages.index": {
            uri: "admin/cms/pages",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.cms/pages.create": {
            uri: "admin/cms/pages/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.cms/pages.edit": {
            uri: "admin/cms/pages/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "filament.resources.redirects.index": {
            uri: "admin/redirects",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.redirects.create": {
            uri: "admin/redirects/create",
            methods: ["GET", "HEAD"],
        },
        "filament.resources.redirects.edit": {
            uri: "admin/redirects/{record}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["record"],
        },
        "breezy.password.request": {
            uri: "admin/password/reset",
            methods: ["GET", "HEAD"],
        },
        "breezy.password.reset": {
            uri: "admin/password/reset/{token}",
            methods: ["GET", "HEAD"],
            parameters: ["token"],
        },
        "breezy.verification.notice": {
            uri: "admin/email/verify",
            methods: ["GET", "HEAD"],
        },
        "breezy.verification.verify": {
            uri: "admin/email/verify/{id}/{hash}",
            methods: ["GET", "HEAD"],
            parameters: ["id", "hash"],
        },
        "sanctum.csrf-cookie": {
            uri: "sanctum/csrf-cookie",
            methods: ["GET", "HEAD"],
        },
        telescope: {
            uri: "telescope/{view?}",
            methods: ["GET", "HEAD"],
            wheres: { view: "(.*)" },
            parameters: ["view"],
        },
        "livewire.message": {
            uri: "livewire/message/{name}",
            methods: ["POST"],
            parameters: ["name"],
        },
        "livewire.message-localized": {
            uri: "{locale}/livewire/message/{name}",
            methods: ["POST"],
            parameters: ["locale", "name"],
        },
        "livewire.upload-file": {
            uri: "livewire/upload-file",
            methods: ["POST"],
        },
        "livewire.preview-file": {
            uri: "livewire/preview-file/{filename}",
            methods: ["GET", "HEAD"],
            parameters: ["filename"],
        },
        "ignition.healthCheck": {
            uri: "_ignition/health-check",
            methods: ["GET", "HEAD"],
        },
        "ignition.executeSolution": {
            uri: "_ignition/execute-solution",
            methods: ["POST"],
        },
        "ignition.updateConfig": {
            uri: "_ignition/update-config",
            methods: ["POST"],
        },
        loginLinkLogin: { uri: "laravel-login-link-login", methods: ["POST"] },
        "api.v0.companies.index": {
            uri: "api/v0/companies",
            methods: ["GET", "HEAD"],
        },
        "api.v0.companies.store": {
            uri: "api/v0/companies",
            methods: ["POST"],
        },
        "api.v0.companies.update": {
            uri: "api/v0/companies/{company}",
            methods: ["PUT", "PATCH"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "api.v0.companies.destroy": {
            uri: "api/v0/companies/{company}",
            methods: ["DELETE"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "api.v0.collective-labour-agreements.index": {
            uri: "api/v0/collective-labour-agreements",
            methods: ["GET", "HEAD"],
        },
        "api.v0.kvk-controller.index": {
            uri: "api/v0/kvk",
            methods: ["GET", "HEAD"],
        },
        "api.v0.kvk-controller.show": {
            uri: "api/v0/kvk/{kvk_number}",
            methods: ["GET", "HEAD"],
            parameters: ["kvk_number"],
        },
        "api.v0.firebase-auth-token.index": {
            uri: "api/v0/firebase-auth-token",
            methods: ["GET", "HEAD"],
        },
        "api.v0.user.index": { uri: "api/v0/user", methods: ["GET", "HEAD"] },
        "api.v0.user.update": { uri: "api/v0/user", methods: ["PUT"] },
        "api.v0.user.destroy": { uri: "api/v0/user", methods: ["DELETE"] },
        "api.v0.user.affiliates": {
            uri: "api/v0/user/affiliates",
            methods: ["GET", "HEAD"],
        },
        "api.v0.user.update-password": {
            uri: "api/v0/user/password",
            methods: ["PUT"],
        },
        "api.v0.user-identification.store": {
            uri: "api/v0/user-identification",
            methods: ["POST"],
        },
        "api.v0.user-selfie.store": {
            uri: "api/v0/user-selfie",
            methods: ["POST"],
        },
        "api.v0.addresses.index": {
            uri: "api/v0/addresses",
            methods: ["GET", "HEAD"],
        },
        "api.v0.addresses.update": {
            uri: "api/v0/addresses/{address}",
            methods: ["PUT", "PATCH"],
            parameters: ["address"],
            bindings: { address: "id" },
        },
        "api.v0.accepted-applications.store": {
            uri: "api/v0/accepted-applications",
            methods: ["POST"],
        },
        "api.v0.accepted-invitations": {
            uri: "api/v0/accepted-invitations",
            methods: ["POST"],
        },
        "api.v0.reaccepted-applications.store": {
            uri: "api/v0/reaccepted-applications",
            methods: ["POST"],
        },
        "api.v0.reopened-applications.store": {
            uri: "api/v0/reopened-applications",
            methods: ["POST"],
        },
        "api.v0.reapplied-applications.store": {
            uri: "api/v0/reapplied-applications",
            methods: ["POST"],
        },
        "api.v0.rejected-applications.store": {
            uri: "api/v0/rejected-applications",
            methods: ["POST"],
        },
        "api.v0.retracted-applications.store": {
            uri: "api/v0/retracted-applications",
            methods: ["POST"],
        },
        "api.v0.reviews.index": {
            uri: "api/v0/reviews",
            methods: ["GET", "HEAD"],
        },
        "api.v0.reviews.store": { uri: "api/v0/reviews", methods: ["POST"] },
        "api.v0.reviews.update": {
            uri: "api/v0/reviews/{review}",
            methods: ["PUT", "PATCH"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "api.v0.reviews.destroy": {
            uri: "api/v0/reviews/{review}",
            methods: ["DELETE"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "api.v0.routes.index": {
            uri: "api/v0/routes",
            methods: ["GET", "HEAD"],
        },
        "api.v0.shifts.update": {
            uri: "api/v0/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "api.v0.shifts.contracts.index": {
            uri: "api/v0/shifts/{shift}/contract",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
            bindings: { shift: "id" },
        },
        "api.v0.disputed-shifts.store": {
            uri: "api/v0/disputed-shifts",
            methods: ["POST"],
        },
        "api.v0.liked-shifts.index": {
            uri: "api/v0/liked-shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v0.liked-shifts.store": {
            uri: "api/v0/liked-shifts",
            methods: ["POST"],
        },
        "api.v0.liked-shifts.update": {
            uri: "api/v0/liked-shifts/{liked_shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_shift"],
            bindings: { liked_shift: "id" },
        },
        "api.v0.liked-shifts.destroy": {
            uri: "api/v0/liked-shifts/{liked_shift}",
            methods: ["DELETE"],
            parameters: ["liked_shift"],
            bindings: { liked_shift: "id" },
        },
        "api.v0.accredited-shifts.store": {
            uri: "api/v0/accredited-shifts",
            methods: ["POST"],
        },
        "api.v0.amended-shifts.store": {
            uri: "api/v0/amended-shifts",
            methods: ["POST"],
        },
        "api.v0.completed-shifts.store": {
            uri: "api/v0/completed-shifts",
            methods: ["POST"],
        },
        "api.v0.retracted-shifts.store": {
            uri: "api/v0/retracted-shifts",
            methods: ["POST"],
        },
        "api.v0.suitable-shifts.index": {
            uri: "api/v0/suitable-shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v0.token.destroy.current": {
            uri: "api/v0/token/current",
            methods: ["DELETE"],
        },
        "api.v0.token.destroy.all": {
            uri: "api/v0/token/all",
            methods: ["POST"],
        },
        "api.v0.phone-verifications.store": {
            uri: "api/v0/phone-verifications",
            methods: ["POST"],
        },
        "api.v0.phone-verifications.update": {
            uri: "api/v0/phone-verifications/{phone_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["phone_verification"],
        },
        "api.v0.locations.index": {
            uri: "api/v0/locations",
            methods: ["GET", "HEAD"],
        },
        "api.v0.locations.store": {
            uri: "api/v0/locations",
            methods: ["POST"],
        },
        "api.v0.locations.update": {
            uri: "api/v0/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.destroy": {
            uri: "api/v0/locations/{location}",
            methods: ["DELETE"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.memberships.index": {
            uri: "api/v0/memberships",
            methods: ["GET", "HEAD"],
        },
        "api.v0.memberships.store": {
            uri: "api/v0/memberships",
            methods: ["POST"],
        },
        "api.v0.memberships.show": {
            uri: "api/v0/memberships/{membership}",
            methods: ["GET", "HEAD"],
            parameters: ["membership"],
        },
        "api.v0.memberships.update": {
            uri: "api/v0/memberships/{membership}",
            methods: ["PUT", "PATCH"],
            parameters: ["membership"],
        },
        "api.v0.memberships.destroy": {
            uri: "api/v0/memberships/{membership}",
            methods: ["DELETE"],
            parameters: ["membership"],
        },
        "api.v0.locations.liked-flexers.index": {
            uri: "api/v0/locations/{location}/liked-flexers",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.liked-flexers.store": {
            uri: "api/v0/locations/{location}/liked-flexers",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.liked-flexers.update": {
            uri: "api/v0/locations/{location}/liked-flexers/{liked_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "liked_flexer"],
            bindings: { location: "id", liked_flexer: "id" },
        },
        "api.v0.locations.liked-flexers.destroy": {
            uri: "api/v0/locations/{location}/liked-flexers/{liked_flexer}",
            methods: ["DELETE"],
            parameters: ["location", "liked_flexer"],
            bindings: { location: "id", liked_flexer: "id" },
        },
        "api.v0.locations.muted-flexers.index": {
            uri: "api/v0/locations/{location}/muted-flexers",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.muted-flexers.store": {
            uri: "api/v0/locations/{location}/muted-flexers",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.muted-flexers.update": {
            uri: "api/v0/locations/{location}/muted-flexers/{muted_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "muted_flexer"],
            bindings: { location: "id", muted_flexer: "id" },
        },
        "api.v0.locations.muted-flexers.destroy": {
            uri: "api/v0/locations/{location}/muted-flexers/{muted_flexer}",
            methods: ["DELETE"],
            parameters: ["location", "muted_flexer"],
            bindings: { location: "id", muted_flexer: "id" },
        },
        "api.v0.locations.hidden-flexers.index": {
            uri: "api/v0/locations/{location}/hidden-flexers",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.hidden-flexers.store": {
            uri: "api/v0/locations/{location}/hidden-flexers",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.hidden-flexers.update": {
            uri: "api/v0/locations/{location}/hidden-flexers/{hidden_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "hidden_flexer"],
            bindings: { location: "id", hidden_flexer: "id" },
        },
        "api.v0.locations.hidden-flexers.destroy": {
            uri: "api/v0/locations/{location}/hidden-flexers/{hidden_flexer}",
            methods: ["DELETE"],
            parameters: ["location", "hidden_flexer"],
            bindings: { location: "id", hidden_flexer: "id" },
        },
        "api.v0.locations.applications.index": {
            uri: "api/v0/locations/{location}/applications",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.applicants.index": {
            uri: "api/v0/locations/{location}/applicants",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.shifts.index": {
            uri: "api/v0/locations/{location}/shifts",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "api.v0.locations.shifts.store": {
            uri: "api/v0/locations/{location}/shifts",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.shift_of_main.index": {
            uri: "api/v0/shift_of_main/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "api.v0.locations.all-date-shifts.index": {
            uri: "api/v0/all-date-shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v0.locations.liked-shifts.index": {
            uri: "api/v0/locations/{location}/liked-shifts",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.locations.invoices.index": {
            uri: "api/v0/locations/{location}/invoices",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.invoices.index": {
            uri: "api/v0/invoices",
            methods: ["GET", "HEAD"],
        },
        "api.v0.locations.positions.index": {
            uri: "api/v0/locations/{location}/positions",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.positions.index": {
            uri: "api/v0/positions",
            methods: ["GET", "HEAD"],
        },
        "api.v0.liked-locations.index": {
            uri: "api/v0/liked-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v0.liked-locations.store": {
            uri: "api/v0/liked-locations",
            methods: ["POST"],
        },
        "api.v0.liked-locations.update": {
            uri: "api/v0/liked-locations/{liked_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_location"],
            bindings: { liked_location: "id" },
        },
        "api.v0.liked-locations.destroy": {
            uri: "api/v0/liked-locations/{liked_location}",
            methods: ["DELETE"],
            parameters: ["liked_location"],
            bindings: { liked_location: "id" },
        },
        "api.v0.muted-locations.index": {
            uri: "api/v0/muted-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v0.muted-locations.store": {
            uri: "api/v0/muted-locations",
            methods: ["POST"],
        },
        "api.v0.muted-locations.update": {
            uri: "api/v0/muted-locations/{muted_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["muted_location"],
            bindings: { muted_location: "id" },
        },
        "api.v0.muted-locations.destroy": {
            uri: "api/v0/muted-locations/{muted_location}",
            methods: ["DELETE"],
            parameters: ["muted_location"],
            bindings: { muted_location: "id" },
        },
        "api.v0.hidden-locations.index": {
            uri: "api/v0/hidden-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v0.hidden-locations.store": {
            uri: "api/v0/hidden-locations",
            methods: ["POST"],
        },
        "api.v0.hidden-locations.update": {
            uri: "api/v0/hidden-locations/{hidden_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["hidden_location"],
            bindings: { hidden_location: "id" },
        },
        "api.v0.hidden-locations.destroy": {
            uri: "api/v0/hidden-locations/{hidden_location}",
            methods: ["DELETE"],
            parameters: ["hidden_location"],
            bindings: { hidden_location: "id" },
        },
        "api.v0.locations.media.store": {
            uri: "api/v0/locations/{location}/media",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v0.registration-tokens.store": {
            uri: "api/v0/registration-tokens",
            methods: ["POST"],
        },
        "api.v0.iban-check.index": {
            uri: "api/v0/iban-check",
            methods: ["GET", "HEAD"],
        },
        "api.v0.user.media.store": {
            uri: "api/v0/user/media",
            methods: ["POST"],
        },
        "api.v0.liked-flexers.index": {
            uri: "api/v0/liked-flexers",
            methods: ["GET", "HEAD"],
        },
        "api.v0.liked-flexers.store": {
            uri: "api/v0/liked-flexers",
            methods: ["POST"],
        },
        "api.v0.liked-flexers.update": {
            uri: "api/v0/liked-flexers/{liked_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "api.v0.liked-flexers.destroy": {
            uri: "api/v0/liked-flexers/{liked_flexer}",
            methods: ["DELETE"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "api.v0.flexers.related-locations": {
            uri: "api/v0/related-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v0.flexers.trainings": {
            uri: "api/v0/trainings",
            methods: ["GET", "HEAD"],
        },
        "api.v0.dresscodes.index": {
            uri: "api/v0/dresscodes",
            methods: ["GET", "HEAD"],
        },
        "api.v0.applications.index": {
            uri: "api/v0/applications",
            methods: ["GET", "HEAD"],
        },
        "api.v0.applications.store": {
            uri: "api/v0/applications",
            methods: ["POST"],
        },
        "api.v0.applications.update": {
            uri: "api/v0/applications/{application}",
            methods: ["PUT", "PATCH"],
            parameters: ["application"],
            bindings: { application: "id" },
        },
        "api.v0.active-shift.index": {
            uri: "api/v0/active-shift",
            methods: ["GET", "HEAD"],
        },
        "api.v0.next-actions.index": {
            uri: "api/v0/next-actions",
            methods: ["GET", "HEAD"],
        },
        "api.v0.scan-log.index": {
            uri: "api/v0/scan-log",
            methods: ["GET", "HEAD"],
        },
        "api.v0.scan-log.store": { uri: "api/v0/scan-log", methods: ["POST"] },
        "api.v0.contracts.index": {
            uri: "api/v0/contracts",
            methods: ["GET", "HEAD"],
        },
        "api.v0.other-work-experience.index": {
            uri: "api/v0/other-work-experience",
            methods: ["GET", "HEAD"],
        },
        "api.v0.other-work-experience.store": {
            uri: "api/v0/other-work-experience",
            methods: ["POST"],
        },
        "api.v0.other-work-experience.destroy": {
            uri: "api/v0/other-work-experience/{other_work_experience}",
            methods: ["DELETE"],
            parameters: ["other_work_experience"],
            bindings: { other_work_experience: "id" },
        },
        "api.v0.work-experience": {
            uri: "api/v0/work-experience",
            methods: ["GET", "HEAD"],
        },
        "api.v0.invited-applications.store": {
            uri: "api/v0/invited-applications",
            methods: ["POST"],
        },
        "api.v0.faqs.index": { uri: "api/v0/faqs", methods: ["GET", "HEAD"] },
        "api.v0.purchase-invoices.index": {
            uri: "api/v0/purchase-invoices",
            methods: ["GET", "HEAD"],
        },
        "api.v0.token.store": { uri: "api/v0/token", methods: ["POST"] },
        "api.v0.token.destroy": {
            uri: "api/v0/token/{token}",
            methods: ["DELETE"],
            parameters: ["token"],
            bindings: { token: "id" },
        },
        "api.v0.token.refresh": {
            uri: "api/v0/token/refresh",
            methods: ["POST"],
        },
        "api.v0.users.index": { uri: "api/v0/users", methods: ["GET", "HEAD"] },
        "api.v0.user.store": { uri: "api/v0/user", methods: ["POST"] },
        "api.v0.shifts.index": {
            uri: "api/v0/shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v0.address-check.index": {
            uri: "api/v0/address-check",
            methods: ["GET", "HEAD"],
        },
        "api.v0.place-job-offer.kvk-controller.show": {
            uri: "api/v0/place-job-offer/kvk/{kvk_number}",
            methods: ["GET", "HEAD"],
            parameters: ["kvk_number"],
        },
        "api.v0.place-job-offer.kvk-controller.index": {
            uri: "api/v0/place-job-offer/kvk",
            methods: ["GET", "HEAD"],
        },
        "api.v0.media.index": { uri: "api/v0/media", methods: ["GET", "HEAD"] },
        "api.v0.email-verifications.store": {
            uri: "api/v0/email-verifications",
            methods: ["POST"],
        },
        "api.v0.email-verifications.update": {
            uri: "api/v0/email-verifications/{email_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["email_verification"],
        },
        "api.v0.reset.index": { uri: "api/v0/reset", methods: ["GET", "HEAD"] },
        "api.v1.companies.index": {
            uri: "api/v1/companies",
            methods: ["GET", "HEAD"],
        },
        "api.v1.companies.store": {
            uri: "api/v1/companies",
            methods: ["POST"],
        },
        "api.v1.companies.update": {
            uri: "api/v1/companies/{company}",
            methods: ["PUT", "PATCH"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "api.v1.companies.destroy": {
            uri: "api/v1/companies/{company}",
            methods: ["DELETE"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "api.v1.collective-labour-agreements.index": {
            uri: "api/v1/collective-labour-agreements",
            methods: ["GET", "HEAD"],
        },
        "api.v1.kvk-controller.index": {
            uri: "api/v1/kvk",
            methods: ["GET", "HEAD"],
        },
        "api.v1.kvk-controller.show": {
            uri: "api/v1/kvk/{kvk_number}",
            methods: ["GET", "HEAD"],
            parameters: ["kvk_number"],
        },
        "api.v1.firebase-auth-token.index": {
            uri: "api/v1/firebase-auth-token",
            methods: ["GET", "HEAD"],
        },
        "api.v1.user.index": { uri: "api/v1/user", methods: ["GET", "HEAD"] },
        "api.v1.user.update": { uri: "api/v1/user", methods: ["PUT"] },
        "api.v1.user.destroy": { uri: "api/v1/user", methods: ["DELETE"] },
        "api.v1.user.affiliates": {
            uri: "api/v1/user/affiliates",
            methods: ["GET", "HEAD"],
        },
        "api.v1.user.update-password": {
            uri: "api/v1/user/password",
            methods: ["PUT"],
        },
        "api.v1.user-identification.store": {
            uri: "api/v1/user-identification",
            methods: ["POST"],
        },
        "api.v1.user-selfie.store": {
            uri: "api/v1/user-selfie",
            methods: ["POST"],
        },
        "api.v1.addresses.index": {
            uri: "api/v1/addresses",
            methods: ["GET", "HEAD"],
        },
        "api.v1.addresses.update": {
            uri: "api/v1/addresses/{address}",
            methods: ["PUT", "PATCH"],
            parameters: ["address"],
            bindings: { address: "id" },
        },
        "api.v1.accepted-applications.store": {
            uri: "api/v1/accepted-applications",
            methods: ["POST"],
        },
        "api.v1.accepted-invitations": {
            uri: "api/v1/accepted-invitations",
            methods: ["POST"],
        },
        "api.v1.reaccepted-applications.store": {
            uri: "api/v1/reaccepted-applications",
            methods: ["POST"],
        },
        "api.v1.reopened-applications.store": {
            uri: "api/v1/reopened-applications",
            methods: ["POST"],
        },
        "api.v1.reapplied-applications.store": {
            uri: "api/v1/reapplied-applications",
            methods: ["POST"],
        },
        "api.v1.rejected-applications.store": {
            uri: "api/v1/rejected-applications",
            methods: ["POST"],
        },
        "api.v1.retracted-applications.store": {
            uri: "api/v1/retracted-applications",
            methods: ["POST"],
        },
        "api.v1.reviews.index": {
            uri: "api/v1/reviews",
            methods: ["GET", "HEAD"],
        },
        "api.v1.reviews.store": { uri: "api/v1/reviews", methods: ["POST"] },
        "api.v1.reviews.update": {
            uri: "api/v1/reviews/{review}",
            methods: ["PUT", "PATCH"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "api.v1.reviews.destroy": {
            uri: "api/v1/reviews/{review}",
            methods: ["DELETE"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "api.v1.routes.index": {
            uri: "api/v1/routes",
            methods: ["GET", "HEAD"],
        },
        "api.v1.shifts.update": {
            uri: "api/v1/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "api.v1.shifts.contracts.index": {
            uri: "api/v1/shifts/{shift}/contract",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
            bindings: { shift: "id" },
        },
        "api.v1.disputed-shifts.store": {
            uri: "api/v1/disputed-shifts",
            methods: ["POST"],
        },
        "api.v1.liked-shifts.index": {
            uri: "api/v1/liked-shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v1.liked-shifts.store": {
            uri: "api/v1/liked-shifts",
            methods: ["POST"],
        },
        "api.v1.liked-shifts.update": {
            uri: "api/v1/liked-shifts/{liked_shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_shift"],
            bindings: { liked_shift: "id" },
        },
        "api.v1.liked-shifts.destroy": {
            uri: "api/v1/liked-shifts/{liked_shift}",
            methods: ["DELETE"],
            parameters: ["liked_shift"],
            bindings: { liked_shift: "id" },
        },
        "api.v1.accredited-shifts.store": {
            uri: "api/v1/accredited-shifts",
            methods: ["POST"],
        },
        "api.v1.amended-shifts.store": {
            uri: "api/v1/amended-shifts",
            methods: ["POST"],
        },
        "api.v1.completed-shifts.store": {
            uri: "api/v1/completed-shifts",
            methods: ["POST"],
        },
        "api.v1.retracted-shifts.store": {
            uri: "api/v1/retracted-shifts",
            methods: ["POST"],
        },
        "api.v1.suitable-shifts.index": {
            uri: "api/v1/suitable-shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v1.token.destroy.current": {
            uri: "api/v1/token/current",
            methods: ["DELETE"],
        },
        "api.v1.token.destroy.all": {
            uri: "api/v1/token/all",
            methods: ["POST"],
        },
        "api.v1.phone-verifications.store": {
            uri: "api/v1/phone-verifications",
            methods: ["POST"],
        },
        "api.v1.phone-verifications.update": {
            uri: "api/v1/phone-verifications/{phone_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["phone_verification"],
        },
        "api.v1.locations.index": {
            uri: "api/v1/locations",
            methods: ["GET", "HEAD"],
        },
        "api.v1.locations.store": {
            uri: "api/v1/locations",
            methods: ["POST"],
        },
        "api.v1.locations.update": {
            uri: "api/v1/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.destroy": {
            uri: "api/v1/locations/{location}",
            methods: ["DELETE"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.memberships.index": {
            uri: "api/v1/memberships",
            methods: ["GET", "HEAD"],
        },
        "api.v1.memberships.store": {
            uri: "api/v1/memberships",
            methods: ["POST"],
        },
        "api.v1.memberships.show": {
            uri: "api/v1/memberships/{membership}",
            methods: ["GET", "HEAD"],
            parameters: ["membership"],
        },
        "api.v1.memberships.update": {
            uri: "api/v1/memberships/{membership}",
            methods: ["PUT", "PATCH"],
            parameters: ["membership"],
        },
        "api.v1.memberships.destroy": {
            uri: "api/v1/memberships/{membership}",
            methods: ["DELETE"],
            parameters: ["membership"],
        },
        "api.v1.locations.liked-flexers.index": {
            uri: "api/v1/locations/{location}/liked-flexers",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.liked-flexers.store": {
            uri: "api/v1/locations/{location}/liked-flexers",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.liked-flexers.update": {
            uri: "api/v1/locations/{location}/liked-flexers/{liked_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "liked_flexer"],
            bindings: { location: "id", liked_flexer: "id" },
        },
        "api.v1.locations.liked-flexers.destroy": {
            uri: "api/v1/locations/{location}/liked-flexers/{liked_flexer}",
            methods: ["DELETE"],
            parameters: ["location", "liked_flexer"],
            bindings: { location: "id", liked_flexer: "id" },
        },
        "api.v1.locations.muted-flexers.index": {
            uri: "api/v1/locations/{location}/muted-flexers",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.muted-flexers.store": {
            uri: "api/v1/locations/{location}/muted-flexers",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.muted-flexers.update": {
            uri: "api/v1/locations/{location}/muted-flexers/{muted_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "muted_flexer"],
            bindings: { location: "id", muted_flexer: "id" },
        },
        "api.v1.locations.muted-flexers.destroy": {
            uri: "api/v1/locations/{location}/muted-flexers/{muted_flexer}",
            methods: ["DELETE"],
            parameters: ["location", "muted_flexer"],
            bindings: { location: "id", muted_flexer: "id" },
        },
        "api.v1.locations.hidden-flexers.index": {
            uri: "api/v1/locations/{location}/hidden-flexers",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.hidden-flexers.store": {
            uri: "api/v1/locations/{location}/hidden-flexers",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.hidden-flexers.update": {
            uri: "api/v1/locations/{location}/hidden-flexers/{hidden_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "hidden_flexer"],
            bindings: { location: "id", hidden_flexer: "id" },
        },
        "api.v1.locations.hidden-flexers.destroy": {
            uri: "api/v1/locations/{location}/hidden-flexers/{hidden_flexer}",
            methods: ["DELETE"],
            parameters: ["location", "hidden_flexer"],
            bindings: { location: "id", hidden_flexer: "id" },
        },
        "api.v1.locations.applications.index": {
            uri: "api/v1/locations/{location}/applications",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.applicants.index": {
            uri: "api/v1/locations/{location}/applicants",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.shifts.index": {
            uri: "api/v1/locations/{location}/shifts",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "api.v1.locations.shifts.store": {
            uri: "api/v1/locations/{location}/shifts",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.shift_of_main.index": {
            uri: "api/v1/shift_of_main/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "api.v1.locations.all-date-shifts.index": {
            uri: "api/v1/all-date-shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v1.locations.liked-shifts.index": {
            uri: "api/v1/locations/{location}/liked-shifts",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.locations.invoices.index": {
            uri: "api/v1/locations/{location}/invoices",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.invoices.index": {
            uri: "api/v1/invoices",
            methods: ["GET", "HEAD"],
        },
        "api.v1.locations.positions.index": {
            uri: "api/v1/locations/{location}/positions",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.positions.index": {
            uri: "api/v1/positions",
            methods: ["GET", "HEAD"],
        },
        "api.v1.liked-locations.index": {
            uri: "api/v1/liked-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v1.liked-locations.store": {
            uri: "api/v1/liked-locations",
            methods: ["POST"],
        },
        "api.v1.liked-locations.update": {
            uri: "api/v1/liked-locations/{liked_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_location"],
            bindings: { liked_location: "id" },
        },
        "api.v1.liked-locations.destroy": {
            uri: "api/v1/liked-locations/{liked_location}",
            methods: ["DELETE"],
            parameters: ["liked_location"],
            bindings: { liked_location: "id" },
        },
        "api.v1.muted-locations.index": {
            uri: "api/v1/muted-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v1.muted-locations.store": {
            uri: "api/v1/muted-locations",
            methods: ["POST"],
        },
        "api.v1.muted-locations.update": {
            uri: "api/v1/muted-locations/{muted_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["muted_location"],
            bindings: { muted_location: "id" },
        },
        "api.v1.muted-locations.destroy": {
            uri: "api/v1/muted-locations/{muted_location}",
            methods: ["DELETE"],
            parameters: ["muted_location"],
            bindings: { muted_location: "id" },
        },
        "api.v1.hidden-locations.index": {
            uri: "api/v1/hidden-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v1.hidden-locations.store": {
            uri: "api/v1/hidden-locations",
            methods: ["POST"],
        },
        "api.v1.hidden-locations.update": {
            uri: "api/v1/hidden-locations/{hidden_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["hidden_location"],
            bindings: { hidden_location: "id" },
        },
        "api.v1.hidden-locations.destroy": {
            uri: "api/v1/hidden-locations/{hidden_location}",
            methods: ["DELETE"],
            parameters: ["hidden_location"],
            bindings: { hidden_location: "id" },
        },
        "api.v1.locations.media.store": {
            uri: "api/v1/locations/{location}/media",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "api.v1.registration-tokens.store": {
            uri: "api/v1/registration-tokens",
            methods: ["POST"],
        },
        "api.v1.iban-check.index": {
            uri: "api/v1/iban-check",
            methods: ["GET", "HEAD"],
        },
        "api.v1.user.media.store": {
            uri: "api/v1/user/media",
            methods: ["POST"],
        },
        "api.v1.liked-flexers.index": {
            uri: "api/v1/liked-flexers",
            methods: ["GET", "HEAD"],
        },
        "api.v1.liked-flexers.store": {
            uri: "api/v1/liked-flexers",
            methods: ["POST"],
        },
        "api.v1.liked-flexers.update": {
            uri: "api/v1/liked-flexers/{liked_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "api.v1.liked-flexers.destroy": {
            uri: "api/v1/liked-flexers/{liked_flexer}",
            methods: ["DELETE"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "api.v1.flexers.related-locations": {
            uri: "api/v1/related-locations",
            methods: ["GET", "HEAD"],
        },
        "api.v1.flexers.trainings": {
            uri: "api/v1/trainings",
            methods: ["GET", "HEAD"],
        },
        "api.v1.dresscodes.index": {
            uri: "api/v1/dresscodes",
            methods: ["GET", "HEAD"],
        },
        "api.v1.applications.index": {
            uri: "api/v1/applications",
            methods: ["GET", "HEAD"],
        },
        "api.v1.applications.store": {
            uri: "api/v1/applications",
            methods: ["POST"],
        },
        "api.v1.applications.update": {
            uri: "api/v1/applications/{application}",
            methods: ["PUT", "PATCH"],
            parameters: ["application"],
            bindings: { application: "id" },
        },
        "api.v1.active-shift.index": {
            uri: "api/v1/active-shift",
            methods: ["GET", "HEAD"],
        },
        "api.v1.next-actions.index": {
            uri: "api/v1/next-actions",
            methods: ["GET", "HEAD"],
        },
        "api.v1.scan-log.index": {
            uri: "api/v1/scan-log",
            methods: ["GET", "HEAD"],
        },
        "api.v1.scan-log.store": { uri: "api/v1/scan-log", methods: ["POST"] },
        "api.v1.contracts.index": {
            uri: "api/v1/contracts",
            methods: ["GET", "HEAD"],
        },
        "api.v1.other-work-experience.index": {
            uri: "api/v1/other-work-experience",
            methods: ["GET", "HEAD"],
        },
        "api.v1.other-work-experience.store": {
            uri: "api/v1/other-work-experience",
            methods: ["POST"],
        },
        "api.v1.other-work-experience.destroy": {
            uri: "api/v1/other-work-experience/{other_work_experience}",
            methods: ["DELETE"],
            parameters: ["other_work_experience"],
            bindings: { other_work_experience: "id" },
        },
        "api.v1.work-experience": {
            uri: "api/v1/work-experience",
            methods: ["GET", "HEAD"],
        },
        "api.v1.invited-applications.store": {
            uri: "api/v1/invited-applications",
            methods: ["POST"],
        },
        "api.v1.faqs.index": { uri: "api/v1/faqs", methods: ["GET", "HEAD"] },
        "api.v1.purchase-invoices.index": {
            uri: "api/v1/purchase-invoices",
            methods: ["GET", "HEAD"],
        },
        "api.v1.token.store": { uri: "api/v1/token", methods: ["POST"] },
        "api.v1.token.destroy": {
            uri: "api/v1/token/{token}",
            methods: ["DELETE"],
            parameters: ["token"],
            bindings: { token: "id" },
        },
        "api.v1.token.refresh": {
            uri: "api/v1/token/refresh",
            methods: ["POST"],
        },
        "api.v1.users.index": { uri: "api/v1/users", methods: ["GET", "HEAD"] },
        "api.v1.user.store": { uri: "api/v1/user", methods: ["POST"] },
        "api.v1.shifts.index": {
            uri: "api/v1/shifts",
            methods: ["GET", "HEAD"],
        },
        "api.v1.address-check.index": {
            uri: "api/v1/address-check",
            methods: ["GET", "HEAD"],
        },
        "api.v1.place-job-offer.kvk-controller.show": {
            uri: "api/v1/place-job-offer/kvk/{kvk_number}",
            methods: ["GET", "HEAD"],
            parameters: ["kvk_number"],
        },
        "api.v1.place-job-offer.kvk-controller.index": {
            uri: "api/v1/place-job-offer/kvk",
            methods: ["GET", "HEAD"],
        },
        "api.v1.media.index": { uri: "api/v1/media", methods: ["GET", "HEAD"] },
        "api.v1.email-verifications.store": {
            uri: "api/v1/email-verifications",
            methods: ["POST"],
        },
        "api.v1.email-verifications.update": {
            uri: "api/v1/email-verifications/{email_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["email_verification"],
        },
        "api.v1.reset.index": { uri: "api/v1/reset", methods: ["GET", "HEAD"] },
        "api.v1.absent-applications.store": {
            uri: "api/v1/absent-applications",
            methods: ["POST"],
        },
        "api.v1.no-show-applications.store": {
            uri: "api/v1/no-show-applications",
            methods: ["POST"],
        },
        "api.v1.illness-applications.store": {
            uri: "api/v1/illness-applications",
            methods: ["POST"],
        },
        "nl.public.job_offers.index": {
            uri: "vacatures",
            methods: ["GET", "HEAD"],
        },
        "nl.employers.recruitment-selection.index": {
            uri: "werkgevers/werving-selectie",
            methods: ["GET", "HEAD"],
        },
        "nl.employers.recruitment-selection.apply": {
            uri: "employers/recruitment-selection/apply",
            methods: ["POST"],
        },
        "nl.place-job-offer.index": {
            uri: "werkgevers/plaats-vacature",
            methods: ["GET", "HEAD"],
        },
        "nl.place-job-offer.store": {
            uri: "employers/place-job-offer",
            methods: ["POST"],
        },
        "nl.public.job_offers.show": {
            uri: "vacatures/{jobOffer}",
            methods: ["GET", "HEAD"],
            parameters: ["jobOffer"],
            bindings: { jobOffer: "id" },
        },
        "nl.job-offers.apply": {
            uri: "job-offers/{jobOffer}/apply",
            methods: ["POST"],
            parameters: ["jobOffer"],
            bindings: { jobOffer: "id" },
        },
        "en.login": { uri: "en/login", methods: ["GET", "HEAD"] },
        "en.": {
            uri: "en/frequenlty-asked-questions",
            methods: ["GET", "HEAD"],
        },
        "en.logout": { uri: "en/logout", methods: ["POST"] },
        "en.password.request": {
            uri: "en/forgot-password",
            methods: ["GET", "HEAD"],
        },
        "en.password.reset": {
            uri: "en/reset-password/{token}",
            methods: ["GET", "HEAD"],
            parameters: ["token"],
        },
        "en.password.email": { uri: "en/forgot-password", methods: ["POST"] },
        "en.password.update": { uri: "en/reset-password", methods: ["POST"] },
        "en.register": { uri: "en/register", methods: ["GET", "HEAD"] },
        "en.verification.notice": {
            uri: "en/email/verify",
            methods: ["GET", "HEAD"],
        },
        "en.verification.verify": {
            uri: "en/email/verify/{id}/{hash}",
            methods: ["GET", "HEAD"],
            parameters: ["id", "hash"],
        },
        "en.verification.send": {
            uri: "en/email/verification-notification",
            methods: ["POST"],
        },
        "en.user-profile-information.update": {
            uri: "en/user/profile-information",
            methods: ["PUT"],
        },
        "en.user-password.update": {
            uri: "en/user/password",
            methods: ["PUT"],
        },
        "en.password.confirmation": {
            uri: "en/user/confirmed-password-status",
            methods: ["GET", "HEAD"],
        },
        "en.password.confirm": {
            uri: "en/user/confirm-password",
            methods: ["POST"],
        },
        "en.dashboard": { uri: "en/dashboard", methods: ["GET", "HEAD"] },
        "en.home": { uri: "en", methods: ["GET", "HEAD"] },
        "en.code": {
            uri: "en/c/{code}",
            methods: ["GET", "HEAD"],
            parameters: ["code"],
        },
        "en.brought-in-by": {
            uri: "en/broughtInBy/{code}",
            methods: ["GET", "HEAD"],
            parameters: ["code"],
        },
        "en.contact": { uri: "en/contact", methods: ["GET", "HEAD"] },
        "en.blogs.index": { uri: "en/blog", methods: ["GET", "HEAD"] },
        "en.affiliated-organizations.index": {
            uri: "en/affiliated-organizations",
            methods: ["GET", "HEAD"],
        },
        "en.faqs": {
            uri: "en/frequently-asked-questions",
            methods: ["GET", "HEAD"],
        },
        "en.sitemap": { uri: "en/sitemap", methods: ["GET", "HEAD"] },
        "en.public.pages.index": {
            uri: "en/fallback",
            methods: ["GET", "HEAD"],
        },
        "en.position.show": {
            uri: "en/shifts/positions/{position}",
            methods: ["GET", "HEAD"],
            parameters: ["position"],
            bindings: { position: "slug" },
        },
        "en.blogs.show": {
            uri: "en/blog/{blog}",
            methods: ["GET", "HEAD"],
            parameters: ["blog"],
            bindings: { blog: "slug" },
        },
        "en.terms": { uri: "en/terms", methods: ["GET", "HEAD"] },
        "en.terms.employer": {
            uri: "en/terms-employer",
            methods: ["GET", "HEAD"],
        },
        "en.terms.flexer": { uri: "en/terms-flexer", methods: ["GET", "HEAD"] },
        "en.privacy": { uri: "en/privacy", methods: ["GET", "HEAD"] },
        "en.bitcoin-proxy": {
            uri: "en/bitcoin-proxy",
            methods: ["GET", "HEAD"],
        },
        "en.invoice-example": {
            uri: "en/downloads/factuur-toelichting",
            methods: ["GET", "HEAD"],
        },
        "en.form.contact": { uri: "en/form/contact", methods: ["POST"] },
        "en.form.newsletter": { uri: "en/form/newsletter", methods: ["POST"] },
        "en.public.pages.show": {
            uri: "en/{page}",
            methods: ["GET", "HEAD"],
            parameters: ["page"],
            bindings: { page: "slug" },
        },
        "en.dirty-fix": {
            uri: "en/@imagetools/{slug}",
            methods: ["GET", "HEAD"],
            parameters: ["slug"],
        },
        "en.flexer.shifts.index": {
            uri: "en/flexer/shifts",
            methods: ["GET", "HEAD"],
        },
        "en.flexer.shifts.create": {
            uri: "en/flexer/shifts/create",
            methods: ["GET", "HEAD"],
        },
        "en.flexer.shifts.store": {
            uri: "en/flexer/shifts",
            methods: ["POST"],
        },
        "en.flexer.shifts.show": {
            uri: "en/flexer/shifts/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "en.flexer.shifts.edit": {
            uri: "en/flexer/shifts/{shift}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "en.flexer.shifts.update": {
            uri: "en/flexer/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "en.flexer.shifts.destroy": {
            uri: "en/flexer/shifts/{shift}",
            methods: ["DELETE"],
            parameters: ["shift"],
        },
        "en.terms.show": {
            uri: "en/terms-of-service",
            methods: ["GET", "HEAD"],
        },
        "en.policy.show": {
            uri: "en/privacy-policy",
            methods: ["GET", "HEAD"],
        },
        "en.profile.show": { uri: "en/user/profile", methods: ["GET", "HEAD"] },
        "en.other-browser-sessions.destroy": {
            uri: "en/user/other-browser-sessions",
            methods: ["DELETE"],
        },
        "en.current-user-photo.destroy": {
            uri: "en/user/profile-photo",
            methods: ["DELETE"],
        },
        "en.teams.create": { uri: "en/teams/create", methods: ["GET", "HEAD"] },
        "en.teams.store": { uri: "en/teams", methods: ["POST"] },
        "en.teams.show": {
            uri: "en/teams/{team}",
            methods: ["GET", "HEAD"],
            parameters: ["team"],
        },
        "en.teams.update": {
            uri: "en/teams/{team}",
            methods: ["PUT"],
            parameters: ["team"],
        },
        "en.teams.destroy": {
            uri: "en/teams/{team}",
            methods: ["DELETE"],
            parameters: ["team"],
        },
        "en.current-team.update": { uri: "en/current-team", methods: ["PUT"] },
        "en.team-members.store": {
            uri: "en/teams/{team}/members",
            methods: ["POST"],
            parameters: ["team"],
        },
        "en.team-members.update": {
            uri: "en/teams/{team}/members/{user}",
            methods: ["PUT"],
            parameters: ["team", "user"],
        },
        "en.team-members.destroy": {
            uri: "en/teams/{team}/members/{user}",
            methods: ["DELETE"],
            parameters: ["team", "user"],
        },
        "en.team-invitations.accept": {
            uri: "en/team-invitations/{invitation}",
            methods: ["GET", "HEAD"],
            parameters: ["invitation"],
        },
        "en.team-invitations.destroy": {
            uri: "en/team-invitations/{invitation}",
            methods: ["DELETE"],
            parameters: ["invitation"],
        },
        "en.user.two-factor.destroy": {
            uri: "en/user/two-factor",
            methods: ["DELETE"],
        },
        "en.user.two-factor-backup-codes.index": {
            uri: "en/user/two-factor-backup-codes",
            methods: ["GET", "HEAD"],
        },
        "en.user.two-factor-backup-codes.create": {
            uri: "en/user/two-factor-backup-codes/create",
            methods: ["GET", "HEAD"],
        },
        "en.user.two-factor-backup-codes.store": {
            uri: "en/user/two-factor-backup-codes",
            methods: ["POST"],
        },
        "en.user.two-factor-backup-codes.show": {
            uri: "en/user/two-factor-backup-codes/{two_factor_backup_code}",
            methods: ["GET", "HEAD"],
            parameters: ["two_factor_backup_code"],
        },
        "en.user.two-factor-backup-codes.edit": {
            uri: "en/user/two-factor-backup-codes/{two_factor_backup_code}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["two_factor_backup_code"],
        },
        "en.user.two-factor-backup-codes.update": {
            uri: "en/user/two-factor-backup-codes/{two_factor_backup_code}",
            methods: ["PUT", "PATCH"],
            parameters: ["two_factor_backup_code"],
        },
        "en.user.two-factor-backup-codes.destroy": {
            uri: "en/user/two-factor-backup-codes/{two_factor_backup_code}",
            methods: ["DELETE"],
            parameters: ["two_factor_backup_code"],
        },
        "en.staff.dashboard": {
            uri: "en/staff/dashboard",
            methods: ["GET", "HEAD"],
        },
        "en.staff.chat": { uri: "en/staff/chat", methods: ["GET", "HEAD"] },
        "en.staff.address.index": {
            uri: "en/staff/address",
            methods: ["GET", "HEAD"],
        },
        "en.staff.address.create": {
            uri: "en/staff/address/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.address.store": {
            uri: "en/staff/address",
            methods: ["POST"],
        },
        "en.staff.address.show": {
            uri: "en/staff/address/{address}",
            methods: ["GET", "HEAD"],
            parameters: ["address"],
        },
        "en.staff.address.edit": {
            uri: "en/staff/address/{address}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["address"],
        },
        "en.staff.address.update": {
            uri: "en/staff/address/{address}",
            methods: ["PUT", "PATCH"],
            parameters: ["address"],
            bindings: { address: "id" },
        },
        "en.staff.address.destroy": {
            uri: "en/staff/address/{address}",
            methods: ["DELETE"],
            parameters: ["address"],
        },
        "en.staff.absent-applications.store": {
            uri: "en/staff/absent-applications",
            methods: ["POST"],
        },
        "en.staff.illness-applications.store": {
            uri: "en/staff/illness-applications",
            methods: ["POST"],
        },
        "en.staff.no-show-applications.store": {
            uri: "en/staff/no-show-applications",
            methods: ["POST"],
        },
        "en.staff.companies.index": {
            uri: "en/staff/companies",
            methods: ["GET", "HEAD"],
        },
        "en.staff.companies.create": {
            uri: "en/staff/companies/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.companies.store": {
            uri: "en/staff/companies",
            methods: ["POST"],
        },
        "en.staff.companies.show": {
            uri: "en/staff/companies/{company}",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.staff.companies.edit": {
            uri: "en/staff/companies/{company}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.staff.companies.update": {
            uri: "en/staff/companies/{company}",
            methods: ["PUT", "PATCH"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.staff.companies.destroy": {
            uri: "en/staff/companies/{company}",
            methods: ["DELETE"],
            parameters: ["company"],
        },
        "en.staff.company-data.download": {
            uri: "en/staff/company-data-download",
            methods: ["GET", "HEAD"],
        },
        "en.staff.companies.team-members.index": {
            uri: "en/staff/companies/{company}/team-members",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.staff.companies.team-members.create": {
            uri: "en/staff/companies/{company}/team-members/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.staff.companies.team-members.store": {
            uri: "en/staff/companies/{company}/team-members",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.staff.companies.team-members.show": {
            uri: "en/staff/companies/{company}/team-members/{team_member}",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "en.staff.companies.team-members.edit": {
            uri: "en/staff/companies/{company}/team-members/{team_member}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "en.staff.companies.team-members.update": {
            uri: "en/staff/companies/{company}/team-members/{team_member}",
            methods: ["PUT", "PATCH"],
            parameters: ["company", "team_member"],
        },
        "en.staff.companies.team-members.destroy": {
            uri: "en/staff/companies/{company}/team-members/{team_member}",
            methods: ["DELETE"],
            parameters: ["company", "team_member"],
            bindings: { company: "id" },
        },
        "en.staff.companies.locations.index": {
            uri: "en/staff/companies/{company}/locations",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.staff.companies.locations.create": {
            uri: "en/staff/companies/{company}/locations/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.staff.companies.locations.store": {
            uri: "en/staff/companies/{company}/locations",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.staff.companies.locations.show": {
            uri: "en/staff/companies/{company}/locations/{location}",
            methods: ["GET", "HEAD"],
            parameters: ["company", "location"],
        },
        "en.staff.companies.locations.edit": {
            uri: "en/staff/companies/{company}/locations/{location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company", "location"],
        },
        "en.staff.companies.locations.update": {
            uri: "en/staff/companies/{company}/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["company", "location"],
        },
        "en.staff.companies.locations.destroy": {
            uri: "en/staff/companies/{company}/locations/{location}",
            methods: ["DELETE"],
            parameters: ["company", "location"],
        },
        "en.staff.profile.index": {
            uri: "en/staff/profile",
            methods: ["GET", "HEAD"],
        },
        "en.staff.profile.create": {
            uri: "en/staff/profile/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.profile.store": {
            uri: "en/staff/profile",
            methods: ["POST"],
        },
        "en.staff.profile.show": {
            uri: "en/staff/profile/{profile}",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "en.staff.profile.edit": {
            uri: "en/staff/profile/{profile}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "en.staff.profile.update": {
            uri: "en/staff/profile/{profile}",
            methods: ["PUT", "PATCH"],
            parameters: ["profile"],
        },
        "en.staff.profile.destroy": {
            uri: "en/staff/profile/{profile}",
            methods: ["DELETE"],
            parameters: ["profile"],
        },
        "en.staff.profile.update-password": {
            uri: "en/staff/profile/update-password",
            methods: ["PUT"],
        },
        "en.staff.invoices.index": {
            uri: "en/staff/invoices",
            methods: ["GET", "HEAD"],
        },
        "en.staff.invoices.create": {
            uri: "en/staff/invoices/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.invoices.store": {
            uri: "en/staff/invoices",
            methods: ["POST"],
        },
        "en.staff.invoices.show": {
            uri: "en/staff/invoices/{invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "en.staff.invoices.edit": {
            uri: "en/staff/invoices/{invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "en.staff.invoices.update": {
            uri: "en/staff/invoices/{invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["invoice"],
        },
        "en.staff.invoices.destroy": {
            uri: "en/staff/invoices/{invoice}",
            methods: ["DELETE"],
            parameters: ["invoice"],
        },
        "en.staff.invoices.download": {
            uri: "en/staff/invoices/{id}/download",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "en.staff.open-invoices.index": {
            uri: "en/staff/open-invoices",
            methods: ["GET", "HEAD"],
        },
        "en.staff.open-invoices.create": {
            uri: "en/staff/open-invoices/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.open-invoices.store": {
            uri: "en/staff/open-invoices",
            methods: ["POST"],
        },
        "en.staff.open-invoices.show": {
            uri: "en/staff/open-invoices/{open_invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["open_invoice"],
        },
        "en.staff.open-invoices.edit": {
            uri: "en/staff/open-invoices/{open_invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["open_invoice"],
        },
        "en.staff.open-invoices.update": {
            uri: "en/staff/open-invoices/{open_invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["open_invoice"],
        },
        "en.staff.open-invoices.destroy": {
            uri: "en/staff/open-invoices/{open_invoice}",
            methods: ["DELETE"],
            parameters: ["open_invoice"],
        },
        "en.staff.flexers.blocks.destroy": {
            uri: "en/staff/flexers/{flexer}/blocks",
            methods: ["DELETE"],
            parameters: ["flexer"],
            bindings: { flexer: "id" },
        },
        "en.staff.flexers.index": {
            uri: "en/staff/flexers",
            methods: ["GET", "HEAD"],
        },
        "en.staff.flexers.create": {
            uri: "en/staff/flexers/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.flexers.store": {
            uri: "en/staff/flexers",
            methods: ["POST"],
        },
        "en.staff.flexers.show": {
            uri: "en/staff/flexers/{flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "en.staff.flexers.edit": {
            uri: "en/staff/flexers/{flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "en.staff.flexers.update": {
            uri: "en/staff/flexers/{flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["flexer"],
            bindings: { flexer: "id" },
        },
        "en.staff.flexers.destroy": {
            uri: "en/staff/flexers/{flexer}",
            methods: ["DELETE"],
            parameters: ["flexer"],
        },
        "en.staff.blocked-flexers.create": {
            uri: "en/staff/blocked-flexers/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.blocked-flexers.store": {
            uri: "en/staff/blocked-flexers",
            methods: ["POST"],
        },
        "en.staff.blocked-flexers.destroy": {
            uri: "en/staff/blocked-flexers/{blocked_flexer}",
            methods: ["DELETE"],
            parameters: ["blocked_flexer"],
            bindings: { blocked_flexer: "id" },
        },
        "en.staff.user-data.download": {
            uri: "en/staff/user-data-download",
            methods: ["GET", "HEAD"],
        },
        "en.staff.password-reset-link.store": {
            uri: "en/staff/password-reset-link",
            methods: ["POST"],
        },
        "en.staff.two-factor.destroy": {
            uri: "en/staff/two-factor",
            methods: ["POST"],
        },
        "en.staff.faqs.index": {
            uri: "en/staff/faqs",
            methods: ["GET", "HEAD"],
        },
        "en.staff.faqs.create": {
            uri: "en/staff/faqs/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.faqs.store": { uri: "en/staff/faqs", methods: ["POST"] },
        "en.staff.faqs.show": {
            uri: "en/staff/faqs/{faq}",
            methods: ["GET", "HEAD"],
            parameters: ["faq"],
        },
        "en.staff.faqs.edit": {
            uri: "en/staff/faqs/{faq}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["faq"],
        },
        "en.staff.faqs.update": {
            uri: "en/staff/faqs/{faq}",
            methods: ["PUT", "PATCH"],
            parameters: ["faq"],
            bindings: { faq: "id" },
        },
        "en.staff.faqs.destroy": {
            uri: "en/staff/faqs/{faq}",
            methods: ["DELETE"],
            parameters: ["faq"],
            bindings: { faq: "id" },
        },
        "en.staff.faq-categories.index": {
            uri: "en/staff/faq-categories",
            methods: ["GET", "HEAD"],
        },
        "en.staff.faq-categories.create": {
            uri: "en/staff/faq-categories/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.faq-categories.store": {
            uri: "en/staff/faq-categories",
            methods: ["POST"],
        },
        "en.staff.faq-categories.show": {
            uri: "en/staff/faq-categories/{faq_category}",
            methods: ["GET", "HEAD"],
            parameters: ["faq_category"],
        },
        "en.staff.faq-categories.edit": {
            uri: "en/staff/faq-categories/{faq_category}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["faq_category"],
            bindings: { faq_category: "id" },
        },
        "en.staff.faq-categories.update": {
            uri: "en/staff/faq-categories/{faq_category}",
            methods: ["PUT", "PATCH"],
            parameters: ["faq_category"],
            bindings: { faq_category: "id" },
        },
        "en.staff.faq-categories.destroy": {
            uri: "en/staff/faq-categories/{faq_category}",
            methods: ["DELETE"],
            parameters: ["faq_category"],
            bindings: { faq_category: "id" },
        },
        "en.staff.positions.index": {
            uri: "en/staff/positions",
            methods: ["GET", "HEAD"],
        },
        "en.staff.positions.create": {
            uri: "en/staff/positions/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.positions.store": {
            uri: "en/staff/positions",
            methods: ["POST"],
        },
        "en.staff.positions.show": {
            uri: "en/staff/positions/{position}",
            methods: ["GET", "HEAD"],
            parameters: ["position"],
        },
        "en.staff.positions.edit": {
            uri: "en/staff/positions/{position}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["position"],
        },
        "en.staff.positions.update": {
            uri: "en/staff/positions/{position}",
            methods: ["PUT", "PATCH"],
            parameters: ["position"],
        },
        "en.staff.shifts.download": {
            uri: "en/staff/shifts/download",
            methods: ["GET", "HEAD"],
        },
        "en.staff.shifts.index": {
            uri: "en/staff/shifts",
            methods: ["GET", "HEAD"],
        },
        "en.staff.shifts.show": {
            uri: "en/staff/shifts/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "en.staff.shifts.update": {
            uri: "en/staff/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "en.staff.retracted-shifts.store": {
            uri: "en/staff/retracted-shifts",
            methods: ["POST"],
        },
        "en.staff.mark-shift-as-no-show.store": {
            uri: "en/staff/mark-shift-as-no-show",
            methods: ["POST"],
        },
        "en.staff.purchase-invoices.index": {
            uri: "en/staff/purchase-invoices",
            methods: ["GET", "HEAD"],
        },
        "en.staff.purchase-invoices.show": {
            uri: "en/staff/purchase-invoices/{purchase_invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["purchase_invoice"],
        },
        "en.staff.purchase-invoices.edit": {
            uri: "en/staff/purchase-invoices/{purchase_invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["purchase_invoice"],
        },
        "en.staff.purchase-invoices.update": {
            uri: "en/staff/purchase-invoices/{purchase_invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["purchase_invoice"],
        },
        "en.staff.purchase-invoices.download": {
            uri: "en/staff/purchase-invoices/{id}/download",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "en.staff.open-purchase-invoices.index": {
            uri: "en/staff/open-purchase-invoices",
            methods: ["GET", "HEAD"],
        },
        "en.staff.open-purchase-invoices.create": {
            uri: "en/staff/open-purchase-invoices/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.open-purchase-invoices.store": {
            uri: "en/staff/open-purchase-invoices",
            methods: ["POST"],
        },
        "en.staff.open-purchase-invoices.show": {
            uri: "en/staff/open-purchase-invoices/{open_purchase_invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["open_purchase_invoice"],
        },
        "en.staff.open-purchase-invoices.edit": {
            uri: "en/staff/open-purchase-invoices/{open_purchase_invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["open_purchase_invoice"],
        },
        "en.staff.open-purchase-invoices.update": {
            uri: "en/staff/open-purchase-invoices/{open_purchase_invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["open_purchase_invoice"],
        },
        "en.staff.open-purchase-invoices.destroy": {
            uri: "en/staff/open-purchase-invoices/{open_purchase_invoice}",
            methods: ["DELETE"],
            parameters: ["open_purchase_invoice"],
        },
        "en.staff.locations.index": {
            uri: "en/staff/locations",
            methods: ["GET", "HEAD"],
        },
        "en.staff.locations.create": {
            uri: "en/staff/locations/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.locations.store": {
            uri: "en/staff/locations",
            methods: ["POST"],
        },
        "en.staff.locations.show": {
            uri: "en/staff/locations/{location}",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.staff.locations.edit": {
            uri: "en/staff/locations/{location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.staff.locations.update": {
            uri: "en/staff/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "en.staff.locations.destroy": {
            uri: "en/staff/locations/{location}",
            methods: ["DELETE"],
            parameters: ["location"],
        },
        "en.staff.location.download-qr": {
            uri: "en/staff/locations/{location}/download-qr",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "en.staff.location-data.download": {
            uri: "en/staff/location-data-download",
            methods: ["GET", "HEAD"],
        },
        "en.staff.blocked-locations.index": {
            uri: "en/staff/blocked-locations",
            methods: ["GET", "HEAD"],
        },
        "en.staff.blocked-locations.create": {
            uri: "en/staff/blocked-locations/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.blocked-locations.store": {
            uri: "en/staff/blocked-locations",
            methods: ["POST"],
        },
        "en.staff.blocked-locations.show": {
            uri: "en/staff/blocked-locations/{blocked_location}",
            methods: ["GET", "HEAD"],
            parameters: ["blocked_location"],
        },
        "en.staff.blocked-locations.edit": {
            uri: "en/staff/blocked-locations/{blocked_location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["blocked_location"],
        },
        "en.staff.blocked-locations.update": {
            uri: "en/staff/blocked-locations/{blocked_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["blocked_location"],
        },
        "en.staff.blocked-locations.destroy": {
            uri: "en/staff/blocked-locations/{blocked_location}",
            methods: ["DELETE"],
            parameters: ["blocked_location"],
            bindings: { blocked_location: "id" },
        },
        "en.staff.locations.received-reviews.index": {
            uri: "en/staff/locations/{location}/received-reviews",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.staff.locations.received-reviews.create": {
            uri: "en/staff/locations/{location}/received-reviews/create",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.staff.locations.received-reviews.store": {
            uri: "en/staff/locations/{location}/received-reviews",
            methods: ["POST"],
            parameters: ["location"],
        },
        "en.staff.locations.received-reviews.show": {
            uri: "en/staff/locations/{location}/received-reviews/{received_review}",
            methods: ["GET", "HEAD"],
            parameters: ["location", "received_review"],
        },
        "en.staff.locations.received-reviews.edit": {
            uri: "en/staff/locations/{location}/received-reviews/{received_review}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location", "received_review"],
        },
        "en.staff.locations.received-reviews.update": {
            uri: "en/staff/locations/{location}/received-reviews/{received_review}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "received_review"],
        },
        "en.staff.locations.received-reviews.destroy": {
            uri: "en/staff/locations/{location}/received-reviews/{received_review}",
            methods: ["DELETE"],
            parameters: ["location", "received_review"],
        },
        "en.staff.locations.given-reviews.index": {
            uri: "en/staff/locations/{location}/given-reviews",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.staff.locations.given-reviews.create": {
            uri: "en/staff/locations/{location}/given-reviews/create",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.staff.locations.given-reviews.store": {
            uri: "en/staff/locations/{location}/given-reviews",
            methods: ["POST"],
            parameters: ["location"],
        },
        "en.staff.locations.given-reviews.show": {
            uri: "en/staff/locations/{location}/given-reviews/{given_review}",
            methods: ["GET", "HEAD"],
            parameters: ["location", "given_review"],
        },
        "en.staff.locations.given-reviews.edit": {
            uri: "en/staff/locations/{location}/given-reviews/{given_review}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location", "given_review"],
        },
        "en.staff.locations.given-reviews.update": {
            uri: "en/staff/locations/{location}/given-reviews/{given_review}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "given_review"],
        },
        "en.staff.locations.given-reviews.destroy": {
            uri: "en/staff/locations/{location}/given-reviews/{given_review}",
            methods: ["DELETE"],
            parameters: ["location", "given_review"],
        },
        "en.staff.reviews.index": {
            uri: "en/staff/reviews",
            methods: ["GET", "HEAD"],
        },
        "en.staff.reviews.update": {
            uri: "en/staff/reviews/{review}",
            methods: ["PUT", "PATCH"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "en.staff.reviews.destroy": {
            uri: "en/staff/reviews/{review}",
            methods: ["DELETE"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "en.staff.rejected-applications.store": {
            uri: "en/staff/rejected-applications",
            methods: ["POST"],
        },
        "en.staff.management.index": {
            uri: "en/staff/management",
            methods: ["GET", "HEAD"],
        },
        "en.staff.management.qr-code": {
            uri: "en/staff/management/qr-code",
            methods: ["GET", "HEAD"],
        },
        "en.staff.management.salary-notification": {
            uri: "en/staff/management/salary-notification",
            methods: ["GET", "HEAD"],
        },
        "en.staff.management.send-salary-notification": {
            uri: "en/staff/management/send-salary-notification",
            methods: ["GET", "HEAD"],
        },
        "en.staff.management.download-qr": {
            uri: "en/staff/management/download-qr",
            methods: ["GET", "HEAD"],
        },
        "en.staff.send-notification.create": {
            uri: "en/staff/send-notification/create",
            methods: ["GET", "HEAD"],
        },
        "en.staff.send-notification.store": {
            uri: "en/staff/send-notification",
            methods: ["POST"],
        },
        "en.location.accepted-applications.index": {
            uri: "en/location/accepted-applications",
            methods: ["GET", "HEAD"],
        },
        "en.location.accepted-applications.create": {
            uri: "en/location/accepted-applications/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.accepted-applications.store": {
            uri: "en/location/accepted-applications",
            methods: ["POST"],
        },
        "en.location.accepted-applications.show": {
            uri: "en/location/accepted-applications/{accepted_application}",
            methods: ["GET", "HEAD"],
            parameters: ["accepted_application"],
        },
        "en.location.accepted-applications.edit": {
            uri: "en/location/accepted-applications/{accepted_application}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["accepted_application"],
        },
        "en.location.accepted-applications.update": {
            uri: "en/location/accepted-applications/{accepted_application}",
            methods: ["PUT", "PATCH"],
            parameters: ["accepted_application"],
        },
        "en.location.accepted-applications.destroy": {
            uri: "en/location/accepted-applications/{accepted_application}",
            methods: ["DELETE"],
            parameters: ["accepted_application"],
        },
        "en.location.absent-applications.store": {
            uri: "en/location/absent-applications",
            methods: ["POST"],
        },
        "en.location.illness-applications.store": {
            uri: "en/location/illness-applications",
            methods: ["POST"],
        },
        "en.location.no-show-applications.store": {
            uri: "en/location/no-show-applications",
            methods: ["POST"],
        },
        "en.location.invited-applications.store": {
            uri: "en/location/invited-applications",
            methods: ["POST"],
        },
        "en.location.rejected-applications.store": {
            uri: "en/location/rejected-applications",
            methods: ["POST"],
        },
        "en.location.chat.index": {
            uri: "en/location/messages",
            methods: ["GET", "HEAD"],
        },
        "en.location.flexers.index": {
            uri: "en/location/flexers",
            methods: ["GET", "HEAD"],
        },
        "en.location.flexers.create": {
            uri: "en/location/flexers/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.flexers.store": {
            uri: "en/location/flexers",
            methods: ["POST"],
        },
        "en.location.flexers.show": {
            uri: "en/location/flexers/{flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "en.location.flexers.edit": {
            uri: "en/location/flexers/{flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "en.location.flexers.update": {
            uri: "en/location/flexers/{flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["flexer"],
        },
        "en.location.flexers.destroy": {
            uri: "en/location/flexers/{flexer}",
            methods: ["DELETE"],
            parameters: ["flexer"],
        },
        "en.location.liked-flexers.index": {
            uri: "en/location/liked-flexers",
            methods: ["GET", "HEAD"],
        },
        "en.location.liked-flexers.create": {
            uri: "en/location/liked-flexers/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.liked-flexers.store": {
            uri: "en/location/liked-flexers",
            methods: ["POST"],
        },
        "en.location.liked-flexers.show": {
            uri: "en/location/liked-flexers/{liked_flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["liked_flexer"],
        },
        "en.location.liked-flexers.edit": {
            uri: "en/location/liked-flexers/{liked_flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["liked_flexer"],
        },
        "en.location.liked-flexers.update": {
            uri: "en/location/liked-flexers/{liked_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "en.location.liked-flexers.destroy": {
            uri: "en/location/liked-flexers/{liked_flexer}",
            methods: ["DELETE"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "en.location.hidden-flexers.index": {
            uri: "en/location/hidden-flexers",
            methods: ["GET", "HEAD"],
        },
        "en.location.hidden-flexers.create": {
            uri: "en/location/hidden-flexers/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.hidden-flexers.store": {
            uri: "en/location/hidden-flexers",
            methods: ["POST"],
        },
        "en.location.hidden-flexers.show": {
            uri: "en/location/hidden-flexers/{hidden_flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["hidden_flexer"],
        },
        "en.location.hidden-flexers.edit": {
            uri: "en/location/hidden-flexers/{hidden_flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["hidden_flexer"],
        },
        "en.location.hidden-flexers.update": {
            uri: "en/location/hidden-flexers/{hidden_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["hidden_flexer"],
            bindings: { hidden_flexer: "id" },
        },
        "en.location.hidden-flexers.destroy": {
            uri: "en/location/hidden-flexers/{hidden_flexer}",
            methods: ["DELETE"],
            parameters: ["hidden_flexer"],
            bindings: { hidden_flexer: "id" },
        },
        "en.location.invoices.index": {
            uri: "en/location/invoices",
            methods: ["GET", "HEAD"],
        },
        "en.location.invoices.create": {
            uri: "en/location/invoices/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.invoices.store": {
            uri: "en/location/invoices",
            methods: ["POST"],
        },
        "en.location.invoices.show": {
            uri: "en/location/invoices/{invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "en.location.invoices.edit": {
            uri: "en/location/invoices/{invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "en.location.invoices.update": {
            uri: "en/location/invoices/{invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["invoice"],
        },
        "en.location.invoices.destroy": {
            uri: "en/location/invoices/{invoice}",
            methods: ["DELETE"],
            parameters: ["invoice"],
        },
        "en.location.invoices.download": {
            uri: "en/location/invoices/{id}/download",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "en.location.planning.todo.index": {
            uri: "en/location/planning/todo",
            methods: ["GET", "HEAD"],
        },
        "en.location.planning.todo.create": {
            uri: "en/location/planning/todo/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.planning.todo.store": {
            uri: "en/location/planning/todo",
            methods: ["POST"],
        },
        "en.location.planning.todo.show": {
            uri: "en/location/planning/todo/{todo}",
            methods: ["GET", "HEAD"],
            parameters: ["todo"],
        },
        "en.location.planning.todo.edit": {
            uri: "en/location/planning/todo/{todo}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["todo"],
        },
        "en.location.planning.todo.update": {
            uri: "en/location/planning/todo/{todo}",
            methods: ["PUT", "PATCH"],
            parameters: ["todo"],
        },
        "en.location.planning.todo.destroy": {
            uri: "en/location/planning/todo/{todo}",
            methods: ["DELETE"],
            parameters: ["todo"],
        },
        "en.location.planning.index": {
            uri: "en/location/planning",
            methods: ["GET", "HEAD"],
        },
        "en.location.planning.create": {
            uri: "en/location/planning/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.planning.store": {
            uri: "en/location/planning",
            methods: ["POST"],
        },
        "en.location.planning.show": {
            uri: "en/location/planning/{planning}",
            methods: ["GET", "HEAD"],
            parameters: ["planning"],
        },
        "en.location.planning.edit": {
            uri: "en/location/planning/{planning}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["planning"],
        },
        "en.location.planning.update": {
            uri: "en/location/planning/{planning}",
            methods: ["PUT", "PATCH"],
            parameters: ["planning"],
        },
        "en.location.planning.destroy": {
            uri: "en/location/planning/{planning}",
            methods: ["DELETE"],
            parameters: ["planning"],
        },
        "en.location.shifts.index": {
            uri: "en/location/shifts",
            methods: ["GET", "HEAD"],
        },
        "en.location.shifts.create": {
            uri: "en/location/shifts/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.shifts.store": {
            uri: "en/location/shifts",
            methods: ["POST"],
        },
        "en.location.shifts.show": {
            uri: "en/location/shifts/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "en.location.shifts.edit": {
            uri: "en/location/shifts/{shift}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "en.location.shifts.update": {
            uri: "en/location/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "en.location.shifts.destroy": {
            uri: "en/location/shifts/{shift}",
            methods: ["DELETE"],
            parameters: ["shift"],
        },
        "en.location.approve-hours.index": {
            uri: "en/location/approve-hours",
            methods: ["GET", "HEAD"],
        },
        "en.location.approve-hours.create": {
            uri: "en/location/approve-hours/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.approve-hours.store": {
            uri: "en/location/approve-hours",
            methods: ["POST"],
        },
        "en.location.approve-hours.show": {
            uri: "en/location/approve-hours/{approve_hour}",
            methods: ["GET", "HEAD"],
            parameters: ["approve_hour"],
        },
        "en.location.approve-hours.edit": {
            uri: "en/location/approve-hours/{approve_hour}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["approve_hour"],
        },
        "en.location.approve-hours.update": {
            uri: "en/location/approve-hours/{approve_hour}",
            methods: ["PUT", "PATCH"],
            parameters: ["approve_hour"],
        },
        "en.location.approve-hours.destroy": {
            uri: "en/location/approve-hours/{approve_hour}",
            methods: ["DELETE"],
            parameters: ["approve_hour"],
        },
        "en.location.accredited-shifts.store": {
            uri: "en/location/accredited-shifts",
            methods: ["POST"],
        },
        "en.location.amended-shifts.store": {
            uri: "en/location/amended-shifts",
            methods: ["POST"],
        },
        "en.location.mark-shift-as-no-show.store": {
            uri: "en/location/mark-shift-as-no-show",
            methods: ["POST"],
        },
        "en.location.splitted-shifts.store": {
            uri: "en/location/splitted-shifts",
            methods: ["POST"],
        },
        "en.location.retracted-shifts.store": {
            uri: "en/location/retracted-shifts",
            methods: ["POST"],
        },
        "en.location.profile.index": {
            uri: "en/location/profile",
            methods: ["GET", "HEAD"],
        },
        "en.location.profile.create": {
            uri: "en/location/profile/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.profile.store": {
            uri: "en/location/profile",
            methods: ["POST"],
        },
        "en.location.profile.show": {
            uri: "en/location/profile/{profile}",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "en.location.profile.edit": {
            uri: "en/location/profile/{profile}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "en.location.profile.update": {
            uri: "en/location/profile/{profile}",
            methods: ["PUT", "PATCH"],
            parameters: ["profile"],
        },
        "en.location.profile.destroy": {
            uri: "en/location/profile/{profile}",
            methods: ["DELETE"],
            parameters: ["profile"],
        },
        "en.location.profile.update-password": {
            uri: "en/location/profile/update-password",
            methods: ["PUT"],
        },
        "en.location.companies.index": {
            uri: "en/location/companies",
            methods: ["GET", "HEAD"],
        },
        "en.location.companies.create": {
            uri: "en/location/companies/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.companies.store": {
            uri: "en/location/companies",
            methods: ["POST"],
        },
        "en.location.companies.show": {
            uri: "en/location/companies/{company}",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.location.companies.edit": {
            uri: "en/location/companies/{company}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.location.companies.update": {
            uri: "en/location/companies/{company}",
            methods: ["PUT", "PATCH"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.location.companies.destroy": {
            uri: "en/location/companies/{company}",
            methods: ["DELETE"],
            parameters: ["company"],
        },
        "en.location.companies.team-members.index": {
            uri: "en/location/companies/{company}/team-members",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.location.companies.team-members.create": {
            uri: "en/location/companies/{company}/team-members/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "en.location.companies.team-members.store": {
            uri: "en/location/companies/{company}/team-members",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.location.companies.team-members.show": {
            uri: "en/location/companies/{company}/team-members/{team_member}",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "en.location.companies.team-members.edit": {
            uri: "en/location/companies/{company}/team-members/{team_member}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "en.location.companies.team-members.update": {
            uri: "en/location/companies/{company}/team-members/{team_member}",
            methods: ["PUT", "PATCH"],
            parameters: ["company", "team_member"],
        },
        "en.location.companies.team-members.destroy": {
            uri: "en/location/companies/{company}/team-members/{team_member}",
            methods: ["DELETE"],
            parameters: ["company", "team_member"],
            bindings: { company: "id" },
        },
        "en.location.companies.locations.create": {
            uri: "en/location/companies/{company}/locations/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.location.companies.locations.store": {
            uri: "en/location/companies/{company}/locations",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "en.location.locations.index": {
            uri: "en/location/locations",
            methods: ["GET", "HEAD"],
        },
        "en.location.locations.create": {
            uri: "en/location/locations/create",
            methods: ["GET", "HEAD"],
        },
        "en.location.locations.store": {
            uri: "en/location/locations",
            methods: ["POST"],
        },
        "en.location.locations.show": {
            uri: "en/location/locations/{location}",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.location.locations.edit": {
            uri: "en/location/locations/{location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.location.locations.update": {
            uri: "en/location/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "en.location.locations.destroy": {
            uri: "en/location/locations/{location}",
            methods: ["DELETE"],
            parameters: ["location"],
        },
        "en.location.locations.team-members.index": {
            uri: "en/location/locations/{location}/team-members",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.location.locations.team-members.create": {
            uri: "en/location/locations/{location}/team-members/create",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "en.location.locations.team-members.store": {
            uri: "en/location/locations/{location}/team-members",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "en.location.locations.team-members.show": {
            uri: "en/location/locations/{location}/team-members/{team_member}",
            methods: ["GET", "HEAD"],
            parameters: ["location", "team_member"],
        },
        "en.location.locations.team-members.edit": {
            uri: "en/location/locations/{location}/team-members/{team_member}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location", "team_member"],
        },
        "en.location.locations.team-members.update": {
            uri: "en/location/locations/{location}/team-members/{team_member}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "team_member"],
            bindings: { location: "id" },
        },
        "en.location.locations.team-members.destroy": {
            uri: "en/location/locations/{location}/team-members/{team_member}",
            methods: ["DELETE"],
            parameters: ["location", "team_member"],
            bindings: { location: "id" },
        },
        "en.location.management.index": {
            uri: "en/location/management",
            methods: ["GET", "HEAD"],
        },
        "en.location.management.download-qr": {
            uri: "en/location/management/download-qr",
            methods: ["GET", "HEAD"],
        },
        "en.password.create": {
            uri: "en/create-password/{token}",
            methods: ["GET", "HEAD"],
            parameters: ["token"],
        },
        "en.email-verifications.store": {
            uri: "en/email-verifications",
            methods: ["POST"],
        },
        "en.email-verifications.update": {
            uri: "en/email-verifications/{email_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["email_verification"],
        },
        "en.phone-verifications.store": {
            uri: "en/phone-verifications",
            methods: ["POST"],
        },
        "en.phone-verifications.update": {
            uri: "en/phone-verifications/{phone_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["phone_verification"],
        },
        "nl.login": { uri: "login", methods: ["GET", "HEAD"] },
        "nl.": { uri: "veelgestelde-vragen", methods: ["GET", "HEAD"] },
        "nl.logout": { uri: "logout", methods: ["POST"] },
        "nl.password.request": {
            uri: "forgot-password",
            methods: ["GET", "HEAD"],
        },
        "nl.password.reset": {
            uri: "reset-password/{token}",
            methods: ["GET", "HEAD"],
            parameters: ["token"],
        },
        "nl.password.email": { uri: "forgot-password", methods: ["POST"] },
        "nl.password.update": { uri: "reset-password", methods: ["POST"] },
        "nl.register": { uri: "registreer", methods: ["GET", "HEAD"] },
        "nl.verification.notice": {
            uri: "email/verify",
            methods: ["GET", "HEAD"],
        },
        "nl.verification.verify": {
            uri: "email/verify/{id}/{hash}",
            methods: ["GET", "HEAD"],
            parameters: ["id", "hash"],
        },
        "nl.verification.send": {
            uri: "email/verification-notification",
            methods: ["POST"],
        },
        "nl.user-profile-information.update": {
            uri: "user/profile-information",
            methods: ["PUT"],
        },
        "nl.user-password.update": { uri: "user/password", methods: ["PUT"] },
        "nl.password.confirmation": {
            uri: "user/confirmed-password-status",
            methods: ["GET", "HEAD"],
        },
        "nl.password.confirm": {
            uri: "user/confirm-password",
            methods: ["POST"],
        },
        "nl.dashboard": { uri: "dashboard", methods: ["GET", "HEAD"] },
        "nl.home": { uri: "/", methods: ["GET", "HEAD"] },
        "nl.code": {
            uri: "c/{code}",
            methods: ["GET", "HEAD"],
            parameters: ["code"],
        },
        "nl.brought-in-by": {
            uri: "broughtInBy/{code}",
            methods: ["GET", "HEAD"],
            parameters: ["code"],
        },
        "nl.contact": { uri: "contact", methods: ["GET", "HEAD"] },
        "nl.blogs.index": { uri: "blog", methods: ["GET", "HEAD"] },
        "nl.affiliated-organizations.index": {
            uri: "aangesloten-organisaties",
            methods: ["GET", "HEAD"],
        },
        "nl.faqs": {
            uri: "frequently-asked-questions",
            methods: ["GET", "HEAD"],
        },
        "nl.sitemap": { uri: "sitemap", methods: ["GET", "HEAD"] },
        "nl.public.pages.index": { uri: "fallback", methods: ["GET", "HEAD"] },
        "nl.position.show": {
            uri: "shifts/positions/{position}",
            methods: ["GET", "HEAD"],
            parameters: ["position"],
            bindings: { position: "slug" },
        },
        "nl.blogs.show": {
            uri: "blog/{blog}",
            methods: ["GET", "HEAD"],
            parameters: ["blog"],
            bindings: { blog: "slug" },
        },
        "nl.terms": { uri: "algemene-voorwaarden", methods: ["GET", "HEAD"] },
        "nl.terms.employer": {
            uri: "gebruikersvoorwaarden-opdrachtgevers",
            methods: ["GET", "HEAD"],
        },
        "nl.terms.flexer": {
            uri: "gebruikersvoorwaarden-arbeidskrachten",
            methods: ["GET", "HEAD"],
        },
        "nl.privacy": { uri: "privacyverklaring", methods: ["GET", "HEAD"] },
        "nl.bitcoin-proxy": {
            uri: "bitcoin-volmacht",
            methods: ["GET", "HEAD"],
        },
        "nl.invoice-example": {
            uri: "downloads/factuur-toelichting",
            methods: ["GET", "HEAD"],
        },
        "nl.form.contact": { uri: "form/contact", methods: ["POST"] },
        "nl.form.newsletter": { uri: "form/newsletter", methods: ["POST"] },
        "nl.public.pages.show": {
            uri: "{page}",
            methods: ["GET", "HEAD"],
            parameters: ["page"],
            bindings: { page: "slug" },
        },
        "nl.dirty-fix": {
            uri: "@imagetools/{slug}",
            methods: ["GET", "HEAD"],
            parameters: ["slug"],
        },
        "nl.flexer.shifts.index": {
            uri: "flexer/shifts",
            methods: ["GET", "HEAD"],
        },
        "nl.flexer.shifts.create": {
            uri: "flexer/shifts/create",
            methods: ["GET", "HEAD"],
        },
        "nl.flexer.shifts.store": { uri: "flexer/shifts", methods: ["POST"] },
        "nl.flexer.shifts.show": {
            uri: "flexer/shifts/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "nl.flexer.shifts.edit": {
            uri: "flexer/shifts/{shift}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "nl.flexer.shifts.update": {
            uri: "flexer/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "nl.flexer.shifts.destroy": {
            uri: "flexer/shifts/{shift}",
            methods: ["DELETE"],
            parameters: ["shift"],
        },
        "nl.terms.show": { uri: "terms-of-service", methods: ["GET", "HEAD"] },
        "nl.policy.show": { uri: "privacy-policy", methods: ["GET", "HEAD"] },
        "nl.profile.show": { uri: "user/profile", methods: ["GET", "HEAD"] },
        "nl.other-browser-sessions.destroy": {
            uri: "user/other-browser-sessions",
            methods: ["DELETE"],
        },
        "nl.current-user-photo.destroy": {
            uri: "user/profile-photo",
            methods: ["DELETE"],
        },
        "nl.teams.create": { uri: "teams/create", methods: ["GET", "HEAD"] },
        "nl.teams.store": { uri: "teams", methods: ["POST"] },
        "nl.teams.show": {
            uri: "teams/{team}",
            methods: ["GET", "HEAD"],
            parameters: ["team"],
        },
        "nl.teams.update": {
            uri: "teams/{team}",
            methods: ["PUT"],
            parameters: ["team"],
        },
        "nl.teams.destroy": {
            uri: "teams/{team}",
            methods: ["DELETE"],
            parameters: ["team"],
        },
        "nl.current-team.update": { uri: "current-team", methods: ["PUT"] },
        "nl.team-members.store": {
            uri: "teams/{team}/members",
            methods: ["POST"],
            parameters: ["team"],
        },
        "nl.team-members.update": {
            uri: "teams/{team}/members/{user}",
            methods: ["PUT"],
            parameters: ["team", "user"],
        },
        "nl.team-members.destroy": {
            uri: "teams/{team}/members/{user}",
            methods: ["DELETE"],
            parameters: ["team", "user"],
        },
        "nl.team-invitations.accept": {
            uri: "team-invitations/{invitation}",
            methods: ["GET", "HEAD"],
            parameters: ["invitation"],
        },
        "nl.team-invitations.destroy": {
            uri: "team-invitations/{invitation}",
            methods: ["DELETE"],
            parameters: ["invitation"],
        },
        "nl.user.two-factor.destroy": {
            uri: "user/two-factor",
            methods: ["DELETE"],
        },
        "nl.user.two-factor-backup-codes.index": {
            uri: "user/two-factor-backup-codes",
            methods: ["GET", "HEAD"],
        },
        "nl.user.two-factor-backup-codes.create": {
            uri: "user/two-factor-backup-codes/create",
            methods: ["GET", "HEAD"],
        },
        "nl.user.two-factor-backup-codes.store": {
            uri: "user/two-factor-backup-codes",
            methods: ["POST"],
        },
        "nl.user.two-factor-backup-codes.show": {
            uri: "user/two-factor-backup-codes/{two_factor_backup_code}",
            methods: ["GET", "HEAD"],
            parameters: ["two_factor_backup_code"],
        },
        "nl.user.two-factor-backup-codes.edit": {
            uri: "user/two-factor-backup-codes/{two_factor_backup_code}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["two_factor_backup_code"],
        },
        "nl.user.two-factor-backup-codes.update": {
            uri: "user/two-factor-backup-codes/{two_factor_backup_code}",
            methods: ["PUT", "PATCH"],
            parameters: ["two_factor_backup_code"],
        },
        "nl.user.two-factor-backup-codes.destroy": {
            uri: "user/two-factor-backup-codes/{two_factor_backup_code}",
            methods: ["DELETE"],
            parameters: ["two_factor_backup_code"],
        },
        "nl.staff.dashboard": {
            uri: "staff/dashboard",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.chat": { uri: "staff/chat", methods: ["GET", "HEAD"] },
        "nl.staff.address.index": {
            uri: "staff/address",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.address.create": {
            uri: "staff/address/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.address.store": { uri: "staff/address", methods: ["POST"] },
        "nl.staff.address.show": {
            uri: "staff/address/{address}",
            methods: ["GET", "HEAD"],
            parameters: ["address"],
        },
        "nl.staff.address.edit": {
            uri: "staff/address/{address}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["address"],
        },
        "nl.staff.address.update": {
            uri: "staff/address/{address}",
            methods: ["PUT", "PATCH"],
            parameters: ["address"],
            bindings: { address: "id" },
        },
        "nl.staff.address.destroy": {
            uri: "staff/address/{address}",
            methods: ["DELETE"],
            parameters: ["address"],
        },
        "nl.staff.absent-applications.store": {
            uri: "staff/absent-applications",
            methods: ["POST"],
        },
        "nl.staff.illness-applications.store": {
            uri: "staff/illness-applications",
            methods: ["POST"],
        },
        "nl.staff.no-show-applications.store": {
            uri: "staff/no-show-applications",
            methods: ["POST"],
        },
        "nl.staff.companies.index": {
            uri: "staff/companies",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.companies.create": {
            uri: "staff/companies/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.companies.store": {
            uri: "staff/companies",
            methods: ["POST"],
        },
        "nl.staff.companies.show": {
            uri: "staff/companies/{company}",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.staff.companies.edit": {
            uri: "staff/companies/{company}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.staff.companies.update": {
            uri: "staff/companies/{company}",
            methods: ["PUT", "PATCH"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.staff.companies.destroy": {
            uri: "staff/companies/{company}",
            methods: ["DELETE"],
            parameters: ["company"],
        },
        "nl.staff.company-data.download": {
            uri: "staff/company-data-download",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.companies.team-members.index": {
            uri: "staff/companies/{company}/team-members",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.staff.companies.team-members.create": {
            uri: "staff/companies/{company}/team-members/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.staff.companies.team-members.store": {
            uri: "staff/companies/{company}/team-members",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.staff.companies.team-members.show": {
            uri: "staff/companies/{company}/team-members/{team_member}",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "nl.staff.companies.team-members.edit": {
            uri: "staff/companies/{company}/team-members/{team_member}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "nl.staff.companies.team-members.update": {
            uri: "staff/companies/{company}/team-members/{team_member}",
            methods: ["PUT", "PATCH"],
            parameters: ["company", "team_member"],
        },
        "nl.staff.companies.team-members.destroy": {
            uri: "staff/companies/{company}/team-members/{team_member}",
            methods: ["DELETE"],
            parameters: ["company", "team_member"],
            bindings: { company: "id" },
        },
        "nl.staff.companies.locations.index": {
            uri: "staff/companies/{company}/locations",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.staff.companies.locations.create": {
            uri: "staff/companies/{company}/locations/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.staff.companies.locations.store": {
            uri: "staff/companies/{company}/locations",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.staff.companies.locations.show": {
            uri: "staff/companies/{company}/locations/{location}",
            methods: ["GET", "HEAD"],
            parameters: ["company", "location"],
        },
        "nl.staff.companies.locations.edit": {
            uri: "staff/companies/{company}/locations/{location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company", "location"],
        },
        "nl.staff.companies.locations.update": {
            uri: "staff/companies/{company}/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["company", "location"],
        },
        "nl.staff.companies.locations.destroy": {
            uri: "staff/companies/{company}/locations/{location}",
            methods: ["DELETE"],
            parameters: ["company", "location"],
        },
        "nl.staff.profile.index": {
            uri: "staff/profile",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.profile.create": {
            uri: "staff/profile/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.profile.store": { uri: "staff/profile", methods: ["POST"] },
        "nl.staff.profile.show": {
            uri: "staff/profile/{profile}",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "nl.staff.profile.edit": {
            uri: "staff/profile/{profile}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "nl.staff.profile.update": {
            uri: "staff/profile/{profile}",
            methods: ["PUT", "PATCH"],
            parameters: ["profile"],
        },
        "nl.staff.profile.destroy": {
            uri: "staff/profile/{profile}",
            methods: ["DELETE"],
            parameters: ["profile"],
        },
        "nl.staff.profile.update-password": {
            uri: "staff/profile/update-password",
            methods: ["PUT"],
        },
        "nl.staff.invoices.index": {
            uri: "staff/invoices",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.invoices.create": {
            uri: "staff/invoices/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.invoices.store": { uri: "staff/invoices", methods: ["POST"] },
        "nl.staff.invoices.show": {
            uri: "staff/invoices/{invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "nl.staff.invoices.edit": {
            uri: "staff/invoices/{invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "nl.staff.invoices.update": {
            uri: "staff/invoices/{invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["invoice"],
        },
        "nl.staff.invoices.destroy": {
            uri: "staff/invoices/{invoice}",
            methods: ["DELETE"],
            parameters: ["invoice"],
        },
        "nl.staff.invoices.download": {
            uri: "staff/invoices/{id}/download",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "nl.staff.open-invoices.index": {
            uri: "staff/open-invoices",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.open-invoices.create": {
            uri: "staff/open-invoices/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.open-invoices.store": {
            uri: "staff/open-invoices",
            methods: ["POST"],
        },
        "nl.staff.open-invoices.show": {
            uri: "staff/open-invoices/{open_invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["open_invoice"],
        },
        "nl.staff.open-invoices.edit": {
            uri: "staff/open-invoices/{open_invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["open_invoice"],
        },
        "nl.staff.open-invoices.update": {
            uri: "staff/open-invoices/{open_invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["open_invoice"],
        },
        "nl.staff.open-invoices.destroy": {
            uri: "staff/open-invoices/{open_invoice}",
            methods: ["DELETE"],
            parameters: ["open_invoice"],
        },
        "nl.staff.flexers.blocks.destroy": {
            uri: "staff/flexers/{flexer}/blocks",
            methods: ["DELETE"],
            parameters: ["flexer"],
            bindings: { flexer: "id" },
        },
        "nl.staff.flexers.index": {
            uri: "staff/flexers",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.flexers.create": {
            uri: "staff/flexers/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.flexers.store": { uri: "staff/flexers", methods: ["POST"] },
        "nl.staff.flexers.show": {
            uri: "staff/flexers/{flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "nl.staff.flexers.edit": {
            uri: "staff/flexers/{flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "nl.staff.flexers.update": {
            uri: "staff/flexers/{flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["flexer"],
            bindings: { flexer: "id" },
        },
        "nl.staff.flexers.destroy": {
            uri: "staff/flexers/{flexer}",
            methods: ["DELETE"],
            parameters: ["flexer"],
        },
        "nl.staff.blocked-flexers.create": {
            uri: "staff/blocked-flexers/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.blocked-flexers.store": {
            uri: "staff/blocked-flexers",
            methods: ["POST"],
        },
        "nl.staff.blocked-flexers.destroy": {
            uri: "staff/blocked-flexers/{blocked_flexer}",
            methods: ["DELETE"],
            parameters: ["blocked_flexer"],
            bindings: { blocked_flexer: "id" },
        },
        "nl.staff.user-data.download": {
            uri: "staff/user-data-download",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.password-reset-link.store": {
            uri: "staff/password-reset-link",
            methods: ["POST"],
        },
        "nl.staff.two-factor.destroy": {
            uri: "staff/two-factor",
            methods: ["POST"],
        },
        "nl.staff.faqs.index": { uri: "staff/faqs", methods: ["GET", "HEAD"] },
        "nl.staff.faqs.create": {
            uri: "staff/faqs/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.faqs.store": { uri: "staff/faqs", methods: ["POST"] },
        "nl.staff.faqs.show": {
            uri: "staff/faqs/{faq}",
            methods: ["GET", "HEAD"],
            parameters: ["faq"],
        },
        "nl.staff.faqs.edit": {
            uri: "staff/faqs/{faq}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["faq"],
        },
        "nl.staff.faqs.update": {
            uri: "staff/faqs/{faq}",
            methods: ["PUT", "PATCH"],
            parameters: ["faq"],
            bindings: { faq: "id" },
        },
        "nl.staff.faqs.destroy": {
            uri: "staff/faqs/{faq}",
            methods: ["DELETE"],
            parameters: ["faq"],
            bindings: { faq: "id" },
        },
        "nl.staff.faq-categories.index": {
            uri: "staff/faq-categories",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.faq-categories.create": {
            uri: "staff/faq-categories/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.faq-categories.store": {
            uri: "staff/faq-categories",
            methods: ["POST"],
        },
        "nl.staff.faq-categories.show": {
            uri: "staff/faq-categories/{faq_category}",
            methods: ["GET", "HEAD"],
            parameters: ["faq_category"],
        },
        "nl.staff.faq-categories.edit": {
            uri: "staff/faq-categories/{faq_category}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["faq_category"],
            bindings: { faq_category: "id" },
        },
        "nl.staff.faq-categories.update": {
            uri: "staff/faq-categories/{faq_category}",
            methods: ["PUT", "PATCH"],
            parameters: ["faq_category"],
            bindings: { faq_category: "id" },
        },
        "nl.staff.faq-categories.destroy": {
            uri: "staff/faq-categories/{faq_category}",
            methods: ["DELETE"],
            parameters: ["faq_category"],
            bindings: { faq_category: "id" },
        },
        "nl.staff.positions.index": {
            uri: "staff/positions",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.positions.create": {
            uri: "staff/positions/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.positions.store": {
            uri: "staff/positions",
            methods: ["POST"],
        },
        "nl.staff.positions.show": {
            uri: "staff/positions/{position}",
            methods: ["GET", "HEAD"],
            parameters: ["position"],
        },
        "nl.staff.positions.edit": {
            uri: "staff/positions/{position}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["position"],
        },
        "nl.staff.positions.update": {
            uri: "staff/positions/{position}",
            methods: ["PUT", "PATCH"],
            parameters: ["position"],
        },
        "nl.staff.shifts.download": {
            uri: "staff/shifts/download",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.shifts.index": {
            uri: "staff/shifts",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.shifts.show": {
            uri: "staff/shifts/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "nl.staff.shifts.update": {
            uri: "staff/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "nl.staff.retracted-shifts.store": {
            uri: "staff/retracted-shifts",
            methods: ["POST"],
        },
        "nl.staff.mark-shift-as-no-show.store": {
            uri: "staff/mark-shift-as-no-show",
            methods: ["POST"],
        },
        "nl.staff.purchase-invoices.index": {
            uri: "staff/purchase-invoices",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.purchase-invoices.show": {
            uri: "staff/purchase-invoices/{purchase_invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["purchase_invoice"],
        },
        "nl.staff.purchase-invoices.edit": {
            uri: "staff/purchase-invoices/{purchase_invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["purchase_invoice"],
        },
        "nl.staff.purchase-invoices.update": {
            uri: "staff/purchase-invoices/{purchase_invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["purchase_invoice"],
        },
        "nl.staff.purchase-invoices.download": {
            uri: "staff/purchase-invoices/{id}/download",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "nl.staff.open-purchase-invoices.index": {
            uri: "staff/open-purchase-invoices",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.open-purchase-invoices.create": {
            uri: "staff/open-purchase-invoices/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.open-purchase-invoices.store": {
            uri: "staff/open-purchase-invoices",
            methods: ["POST"],
        },
        "nl.staff.open-purchase-invoices.show": {
            uri: "staff/open-purchase-invoices/{open_purchase_invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["open_purchase_invoice"],
        },
        "nl.staff.open-purchase-invoices.edit": {
            uri: "staff/open-purchase-invoices/{open_purchase_invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["open_purchase_invoice"],
        },
        "nl.staff.open-purchase-invoices.update": {
            uri: "staff/open-purchase-invoices/{open_purchase_invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["open_purchase_invoice"],
        },
        "nl.staff.open-purchase-invoices.destroy": {
            uri: "staff/open-purchase-invoices/{open_purchase_invoice}",
            methods: ["DELETE"],
            parameters: ["open_purchase_invoice"],
        },
        "nl.staff.locations.index": {
            uri: "staff/locations",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.locations.create": {
            uri: "staff/locations/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.locations.store": {
            uri: "staff/locations",
            methods: ["POST"],
        },
        "nl.staff.locations.show": {
            uri: "staff/locations/{location}",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.staff.locations.edit": {
            uri: "staff/locations/{location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.staff.locations.update": {
            uri: "staff/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "nl.staff.locations.destroy": {
            uri: "staff/locations/{location}",
            methods: ["DELETE"],
            parameters: ["location"],
        },
        "nl.staff.location.download-qr": {
            uri: "staff/locations/{location}/download-qr",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "nl.staff.location-data.download": {
            uri: "staff/location-data-download",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.blocked-locations.index": {
            uri: "staff/blocked-locations",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.blocked-locations.create": {
            uri: "staff/blocked-locations/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.blocked-locations.store": {
            uri: "staff/blocked-locations",
            methods: ["POST"],
        },
        "nl.staff.blocked-locations.show": {
            uri: "staff/blocked-locations/{blocked_location}",
            methods: ["GET", "HEAD"],
            parameters: ["blocked_location"],
        },
        "nl.staff.blocked-locations.edit": {
            uri: "staff/blocked-locations/{blocked_location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["blocked_location"],
        },
        "nl.staff.blocked-locations.update": {
            uri: "staff/blocked-locations/{blocked_location}",
            methods: ["PUT", "PATCH"],
            parameters: ["blocked_location"],
        },
        "nl.staff.blocked-locations.destroy": {
            uri: "staff/blocked-locations/{blocked_location}",
            methods: ["DELETE"],
            parameters: ["blocked_location"],
            bindings: { blocked_location: "id" },
        },
        "nl.staff.locations.received-reviews.index": {
            uri: "staff/locations/{location}/received-reviews",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.staff.locations.received-reviews.create": {
            uri: "staff/locations/{location}/received-reviews/create",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.staff.locations.received-reviews.store": {
            uri: "staff/locations/{location}/received-reviews",
            methods: ["POST"],
            parameters: ["location"],
        },
        "nl.staff.locations.received-reviews.show": {
            uri: "staff/locations/{location}/received-reviews/{received_review}",
            methods: ["GET", "HEAD"],
            parameters: ["location", "received_review"],
        },
        "nl.staff.locations.received-reviews.edit": {
            uri: "staff/locations/{location}/received-reviews/{received_review}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location", "received_review"],
        },
        "nl.staff.locations.received-reviews.update": {
            uri: "staff/locations/{location}/received-reviews/{received_review}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "received_review"],
        },
        "nl.staff.locations.received-reviews.destroy": {
            uri: "staff/locations/{location}/received-reviews/{received_review}",
            methods: ["DELETE"],
            parameters: ["location", "received_review"],
        },
        "nl.staff.locations.given-reviews.index": {
            uri: "staff/locations/{location}/given-reviews",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.staff.locations.given-reviews.create": {
            uri: "staff/locations/{location}/given-reviews/create",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.staff.locations.given-reviews.store": {
            uri: "staff/locations/{location}/given-reviews",
            methods: ["POST"],
            parameters: ["location"],
        },
        "nl.staff.locations.given-reviews.show": {
            uri: "staff/locations/{location}/given-reviews/{given_review}",
            methods: ["GET", "HEAD"],
            parameters: ["location", "given_review"],
        },
        "nl.staff.locations.given-reviews.edit": {
            uri: "staff/locations/{location}/given-reviews/{given_review}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location", "given_review"],
        },
        "nl.staff.locations.given-reviews.update": {
            uri: "staff/locations/{location}/given-reviews/{given_review}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "given_review"],
        },
        "nl.staff.locations.given-reviews.destroy": {
            uri: "staff/locations/{location}/given-reviews/{given_review}",
            methods: ["DELETE"],
            parameters: ["location", "given_review"],
        },
        "nl.staff.reviews.index": {
            uri: "staff/reviews",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.reviews.update": {
            uri: "staff/reviews/{review}",
            methods: ["PUT", "PATCH"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "nl.staff.reviews.destroy": {
            uri: "staff/reviews/{review}",
            methods: ["DELETE"],
            parameters: ["review"],
            bindings: { review: "id" },
        },
        "nl.staff.rejected-applications.store": {
            uri: "staff/rejected-applications",
            methods: ["POST"],
        },
        "nl.staff.management.index": {
            uri: "staff/management",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.management.qr-code": {
            uri: "staff/management/qr-code",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.management.salary-notification": {
            uri: "staff/management/salary-notification",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.management.send-salary-notification": {
            uri: "staff/management/send-salary-notification",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.management.download-qr": {
            uri: "staff/management/download-qr",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.send-notification.create": {
            uri: "staff/send-notification/create",
            methods: ["GET", "HEAD"],
        },
        "nl.staff.send-notification.store": {
            uri: "staff/send-notification",
            methods: ["POST"],
        },
        "nl.location.accepted-applications.index": {
            uri: "location/accepted-applications",
            methods: ["GET", "HEAD"],
        },
        "nl.location.accepted-applications.create": {
            uri: "location/accepted-applications/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.accepted-applications.store": {
            uri: "location/accepted-applications",
            methods: ["POST"],
        },
        "nl.location.accepted-applications.show": {
            uri: "location/accepted-applications/{accepted_application}",
            methods: ["GET", "HEAD"],
            parameters: ["accepted_application"],
        },
        "nl.location.accepted-applications.edit": {
            uri: "location/accepted-applications/{accepted_application}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["accepted_application"],
        },
        "nl.location.accepted-applications.update": {
            uri: "location/accepted-applications/{accepted_application}",
            methods: ["PUT", "PATCH"],
            parameters: ["accepted_application"],
        },
        "nl.location.accepted-applications.destroy": {
            uri: "location/accepted-applications/{accepted_application}",
            methods: ["DELETE"],
            parameters: ["accepted_application"],
        },
        "nl.location.absent-applications.store": {
            uri: "location/absent-applications",
            methods: ["POST"],
        },
        "nl.location.illness-applications.store": {
            uri: "location/illness-applications",
            methods: ["POST"],
        },
        "nl.location.no-show-applications.store": {
            uri: "location/no-show-applications",
            methods: ["POST"],
        },
        "nl.location.invited-applications.store": {
            uri: "location/invited-applications",
            methods: ["POST"],
        },
        "nl.location.rejected-applications.store": {
            uri: "location/rejected-applications",
            methods: ["POST"],
        },
        "nl.location.chat.index": {
            uri: "location/messages",
            methods: ["GET", "HEAD"],
        },
        "nl.location.flexers.index": {
            uri: "location/flexers",
            methods: ["GET", "HEAD"],
        },
        "nl.location.flexers.create": {
            uri: "location/flexers/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.flexers.store": {
            uri: "location/flexers",
            methods: ["POST"],
        },
        "nl.location.flexers.show": {
            uri: "location/flexers/{flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "nl.location.flexers.edit": {
            uri: "location/flexers/{flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["flexer"],
        },
        "nl.location.flexers.update": {
            uri: "location/flexers/{flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["flexer"],
        },
        "nl.location.flexers.destroy": {
            uri: "location/flexers/{flexer}",
            methods: ["DELETE"],
            parameters: ["flexer"],
        },
        "nl.location.liked-flexers.index": {
            uri: "location/liked-flexers",
            methods: ["GET", "HEAD"],
        },
        "nl.location.liked-flexers.create": {
            uri: "location/liked-flexers/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.liked-flexers.store": {
            uri: "location/liked-flexers",
            methods: ["POST"],
        },
        "nl.location.liked-flexers.show": {
            uri: "location/liked-flexers/{liked_flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["liked_flexer"],
        },
        "nl.location.liked-flexers.edit": {
            uri: "location/liked-flexers/{liked_flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["liked_flexer"],
        },
        "nl.location.liked-flexers.update": {
            uri: "location/liked-flexers/{liked_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "nl.location.liked-flexers.destroy": {
            uri: "location/liked-flexers/{liked_flexer}",
            methods: ["DELETE"],
            parameters: ["liked_flexer"],
            bindings: { liked_flexer: "id" },
        },
        "nl.location.hidden-flexers.index": {
            uri: "location/hidden-flexers",
            methods: ["GET", "HEAD"],
        },
        "nl.location.hidden-flexers.create": {
            uri: "location/hidden-flexers/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.hidden-flexers.store": {
            uri: "location/hidden-flexers",
            methods: ["POST"],
        },
        "nl.location.hidden-flexers.show": {
            uri: "location/hidden-flexers/{hidden_flexer}",
            methods: ["GET", "HEAD"],
            parameters: ["hidden_flexer"],
        },
        "nl.location.hidden-flexers.edit": {
            uri: "location/hidden-flexers/{hidden_flexer}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["hidden_flexer"],
        },
        "nl.location.hidden-flexers.update": {
            uri: "location/hidden-flexers/{hidden_flexer}",
            methods: ["PUT", "PATCH"],
            parameters: ["hidden_flexer"],
            bindings: { hidden_flexer: "id" },
        },
        "nl.location.hidden-flexers.destroy": {
            uri: "location/hidden-flexers/{hidden_flexer}",
            methods: ["DELETE"],
            parameters: ["hidden_flexer"],
            bindings: { hidden_flexer: "id" },
        },
        "nl.location.invoices.index": {
            uri: "location/invoices",
            methods: ["GET", "HEAD"],
        },
        "nl.location.invoices.create": {
            uri: "location/invoices/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.invoices.store": {
            uri: "location/invoices",
            methods: ["POST"],
        },
        "nl.location.invoices.show": {
            uri: "location/invoices/{invoice}",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "nl.location.invoices.edit": {
            uri: "location/invoices/{invoice}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["invoice"],
        },
        "nl.location.invoices.update": {
            uri: "location/invoices/{invoice}",
            methods: ["PUT", "PATCH"],
            parameters: ["invoice"],
        },
        "nl.location.invoices.destroy": {
            uri: "location/invoices/{invoice}",
            methods: ["DELETE"],
            parameters: ["invoice"],
        },
        "nl.location.invoices.download": {
            uri: "location/invoices/{id}/download",
            methods: ["GET", "HEAD"],
            parameters: ["id"],
        },
        "nl.location.planning.todo.index": {
            uri: "location/planning/todo",
            methods: ["GET", "HEAD"],
        },
        "nl.location.planning.todo.create": {
            uri: "location/planning/todo/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.planning.todo.store": {
            uri: "location/planning/todo",
            methods: ["POST"],
        },
        "nl.location.planning.todo.show": {
            uri: "location/planning/todo/{todo}",
            methods: ["GET", "HEAD"],
            parameters: ["todo"],
        },
        "nl.location.planning.todo.edit": {
            uri: "location/planning/todo/{todo}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["todo"],
        },
        "nl.location.planning.todo.update": {
            uri: "location/planning/todo/{todo}",
            methods: ["PUT", "PATCH"],
            parameters: ["todo"],
        },
        "nl.location.planning.todo.destroy": {
            uri: "location/planning/todo/{todo}",
            methods: ["DELETE"],
            parameters: ["todo"],
        },
        "nl.location.planning.index": {
            uri: "location/planning",
            methods: ["GET", "HEAD"],
        },
        "nl.location.planning.create": {
            uri: "location/planning/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.planning.store": {
            uri: "location/planning",
            methods: ["POST"],
        },
        "nl.location.planning.show": {
            uri: "location/planning/{planning}",
            methods: ["GET", "HEAD"],
            parameters: ["planning"],
        },
        "nl.location.planning.edit": {
            uri: "location/planning/{planning}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["planning"],
        },
        "nl.location.planning.update": {
            uri: "location/planning/{planning}",
            methods: ["PUT", "PATCH"],
            parameters: ["planning"],
        },
        "nl.location.planning.destroy": {
            uri: "location/planning/{planning}",
            methods: ["DELETE"],
            parameters: ["planning"],
        },
        "nl.location.shifts.index": {
            uri: "location/shifts",
            methods: ["GET", "HEAD"],
        },
        "nl.location.shifts.create": {
            uri: "location/shifts/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.shifts.store": {
            uri: "location/shifts",
            methods: ["POST"],
        },
        "nl.location.shifts.show": {
            uri: "location/shifts/{shift}",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "nl.location.shifts.edit": {
            uri: "location/shifts/{shift}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["shift"],
        },
        "nl.location.shifts.update": {
            uri: "location/shifts/{shift}",
            methods: ["PUT", "PATCH"],
            parameters: ["shift"],
        },
        "nl.location.shifts.destroy": {
            uri: "location/shifts/{shift}",
            methods: ["DELETE"],
            parameters: ["shift"],
        },
        "nl.location.approve-hours.index": {
            uri: "location/approve-hours",
            methods: ["GET", "HEAD"],
        },
        "nl.location.approve-hours.create": {
            uri: "location/approve-hours/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.approve-hours.store": {
            uri: "location/approve-hours",
            methods: ["POST"],
        },
        "nl.location.approve-hours.show": {
            uri: "location/approve-hours/{approve_hour}",
            methods: ["GET", "HEAD"],
            parameters: ["approve_hour"],
        },
        "nl.location.approve-hours.edit": {
            uri: "location/approve-hours/{approve_hour}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["approve_hour"],
        },
        "nl.location.approve-hours.update": {
            uri: "location/approve-hours/{approve_hour}",
            methods: ["PUT", "PATCH"],
            parameters: ["approve_hour"],
        },
        "nl.location.approve-hours.destroy": {
            uri: "location/approve-hours/{approve_hour}",
            methods: ["DELETE"],
            parameters: ["approve_hour"],
        },
        "nl.location.accredited-shifts.store": {
            uri: "location/accredited-shifts",
            methods: ["POST"],
        },
        "nl.location.amended-shifts.store": {
            uri: "location/amended-shifts",
            methods: ["POST"],
        },
        "nl.location.mark-shift-as-no-show.store": {
            uri: "location/mark-shift-as-no-show",
            methods: ["POST"],
        },
        "nl.location.splitted-shifts.store": {
            uri: "location/splitted-shifts",
            methods: ["POST"],
        },
        "nl.location.retracted-shifts.store": {
            uri: "location/retracted-shifts",
            methods: ["POST"],
        },
        "nl.location.profile.index": {
            uri: "location/profile",
            methods: ["GET", "HEAD"],
        },
        "nl.location.profile.create": {
            uri: "location/profile/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.profile.store": {
            uri: "location/profile",
            methods: ["POST"],
        },
        "nl.location.profile.show": {
            uri: "location/profile/{profile}",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "nl.location.profile.edit": {
            uri: "location/profile/{profile}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["profile"],
        },
        "nl.location.profile.update": {
            uri: "location/profile/{profile}",
            methods: ["PUT", "PATCH"],
            parameters: ["profile"],
        },
        "nl.location.profile.destroy": {
            uri: "location/profile/{profile}",
            methods: ["DELETE"],
            parameters: ["profile"],
        },
        "nl.location.profile.update-password": {
            uri: "location/profile/update-password",
            methods: ["PUT"],
        },
        "nl.location.companies.index": {
            uri: "location/companies",
            methods: ["GET", "HEAD"],
        },
        "nl.location.companies.create": {
            uri: "location/companies/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.companies.store": {
            uri: "location/companies",
            methods: ["POST"],
        },
        "nl.location.companies.show": {
            uri: "location/companies/{company}",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.location.companies.edit": {
            uri: "location/companies/{company}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.location.companies.update": {
            uri: "location/companies/{company}",
            methods: ["PUT", "PATCH"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.location.companies.destroy": {
            uri: "location/companies/{company}",
            methods: ["DELETE"],
            parameters: ["company"],
        },
        "nl.location.companies.team-members.index": {
            uri: "location/companies/{company}/team-members",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.location.companies.team-members.create": {
            uri: "location/companies/{company}/team-members/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
        },
        "nl.location.companies.team-members.store": {
            uri: "location/companies/{company}/team-members",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.location.companies.team-members.show": {
            uri: "location/companies/{company}/team-members/{team_member}",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "nl.location.companies.team-members.edit": {
            uri: "location/companies/{company}/team-members/{team_member}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["company", "team_member"],
        },
        "nl.location.companies.team-members.update": {
            uri: "location/companies/{company}/team-members/{team_member}",
            methods: ["PUT", "PATCH"],
            parameters: ["company", "team_member"],
        },
        "nl.location.companies.team-members.destroy": {
            uri: "location/companies/{company}/team-members/{team_member}",
            methods: ["DELETE"],
            parameters: ["company", "team_member"],
            bindings: { company: "id" },
        },
        "nl.location.companies.locations.create": {
            uri: "location/companies/{company}/locations/create",
            methods: ["GET", "HEAD"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.location.companies.locations.store": {
            uri: "location/companies/{company}/locations",
            methods: ["POST"],
            parameters: ["company"],
            bindings: { company: "id" },
        },
        "nl.location.locations.index": {
            uri: "location/locations",
            methods: ["GET", "HEAD"],
        },
        "nl.location.locations.create": {
            uri: "location/locations/create",
            methods: ["GET", "HEAD"],
        },
        "nl.location.locations.store": {
            uri: "location/locations",
            methods: ["POST"],
        },
        "nl.location.locations.show": {
            uri: "location/locations/{location}",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.location.locations.edit": {
            uri: "location/locations/{location}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.location.locations.update": {
            uri: "location/locations/{location}",
            methods: ["PUT", "PATCH"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "nl.location.locations.destroy": {
            uri: "location/locations/{location}",
            methods: ["DELETE"],
            parameters: ["location"],
        },
        "nl.location.locations.team-members.index": {
            uri: "location/locations/{location}/team-members",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.location.locations.team-members.create": {
            uri: "location/locations/{location}/team-members/create",
            methods: ["GET", "HEAD"],
            parameters: ["location"],
        },
        "nl.location.locations.team-members.store": {
            uri: "location/locations/{location}/team-members",
            methods: ["POST"],
            parameters: ["location"],
            bindings: { location: "id" },
        },
        "nl.location.locations.team-members.show": {
            uri: "location/locations/{location}/team-members/{team_member}",
            methods: ["GET", "HEAD"],
            parameters: ["location", "team_member"],
        },
        "nl.location.locations.team-members.edit": {
            uri: "location/locations/{location}/team-members/{team_member}/edit",
            methods: ["GET", "HEAD"],
            parameters: ["location", "team_member"],
        },
        "nl.location.locations.team-members.update": {
            uri: "location/locations/{location}/team-members/{team_member}",
            methods: ["PUT", "PATCH"],
            parameters: ["location", "team_member"],
            bindings: { location: "id" },
        },
        "nl.location.locations.team-members.destroy": {
            uri: "location/locations/{location}/team-members/{team_member}",
            methods: ["DELETE"],
            parameters: ["location", "team_member"],
            bindings: { location: "id" },
        },
        "nl.location.management.index": {
            uri: "location/management",
            methods: ["GET", "HEAD"],
        },
        "nl.location.management.download-qr": {
            uri: "location/management/download-qr",
            methods: ["GET", "HEAD"],
        },
        "nl.password.create": {
            uri: "create-password/{token}",
            methods: ["GET", "HEAD"],
            parameters: ["token"],
        },
        "nl.email-verifications.store": {
            uri: "email-verifications",
            methods: ["POST"],
        },
        "nl.email-verifications.update": {
            uri: "email-verifications/{email_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["email_verification"],
        },
        "nl.phone-verifications.store": {
            uri: "phone-verifications",
            methods: ["POST"],
        },
        "nl.phone-verifications.update": {
            uri: "phone-verifications/{phone_verification}",
            methods: ["PUT", "PATCH"],
            parameters: ["phone_verification"],
        },
    },
};

if (typeof window !== "undefined" && typeof window.Ziggy !== "undefined") {
    Object.assign(Ziggy.routes, window.Ziggy.routes);
}

export { Ziggy };
